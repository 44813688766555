import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const PartnerMobileCss = css`
    min-height: 150px;
    padding: 12px 12px;
    .percentage-and-info {
        .info {
            font-size: ${fonts.size.xxlarge};
            line-height: 30px;
        }
    }
`;

const PartnerTabletCss = css`
    min-height: 200px;
    .percentage-and-info {
        .info {
            font-size: 30px;
            line-height: 35px;
        }
    }
`;

export const Partner = styled.div`
    border-radius: 20px;
    padding: 20px 20px;
    background-color: ${color('base.white')};
    box-shadow: 0px 0px 5px gainsboro;
    .percentage-and-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: ${color('primary.500')};

        .percentage {
            margin-bottom: 0;
            font-size: 52px;
            color: inherit;
        }

        .info {
            font-size: ${fonts.size.xxxlarge};
            line-height: 40px;
            font-weight: 500;
            color: inherit;

            p {
                margin: 0 0;
            }
        }
    }
    ${mediaQuery.lessThan('desktop')`${PartnerTabletCss}`}
    ${mediaQuery.lessThan('tablet')`${PartnerMobileCss}`}
`;
