import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

const FeaturedProductsMobileCss = css`
    .detail {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        .right-side {
            margin-left: 66px;
        }
        .left-side {
            .name-and-subname {
                h6 {
                    font-size: 13px;
                }
                h5 {
                    font-size: 11px;
                }
            }
        }
        .right-side {
            margin-left: 0;
            text-align: center;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
`;

export const FeaturedProducts = styled.div`
    position: relative;
    border-radius: 25px;
    overflow: hidden;

    img {
        max-width: 100%;
        display: block;
    }
    .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: ${color('neutral.100')};
        .left-side {
            display: flex;
            align-items: center;
            img {
                width: 50px;
                height: 50px;
                display: block;
                margin-right: 15px;
                border-radius: 10px;
            }
            .name-and-subname {
                h6 {
                    font-size: ${fonts.size.large};
                    margin: 0;
                    color: ${color('base.black')};
                }
                h5 {
                    font-size: 13px;
                    font-weight: normal;
                    margin: 0 0;
                    color: ${color('base.black')};
                }
            }
        }
        .right-side {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            .status {
            }
            .discount {
                font-weight: 400;
                color: ${color('primary.500')};
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${FeaturedProductsMobileCss}`}
`;
