import React from "react";
import * as S from "./index.styles";
import { Collapse } from "antd";

interface FaqCardProps {
  list: any[];
}

const { Panel } = Collapse;

const AccordionMenu: ({ list }: FaqCardProps) => React.JSX.Element = ({
  list,
}: FaqCardProps) => {
  return (
    <S.AccordionMenuStyled>
      <Collapse>
        {list.map((item: any, index: number) => (
          <Panel header={item?.title} key={index.toString()}>
            <div dangerouslySetInnerHTML={{ __html: item?.content }} />
          </Panel>
        ))}
      </Collapse>
    </S.AccordionMenuStyled>
  );
};

export default AccordionMenu;
