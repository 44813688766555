import * as S from "./index.styles";
import {Merchant} from "../../api/page/page.model";

interface MerchantBoxProps {
    merchant: Merchant
}

const MerchantBox = ({merchant}: MerchantBoxProps) => {

    return (
        <S.Box>
            <a href={merchant.link} target="_blank" title={merchant.merchant} rel="noopener noreferrer">
                <div className='banner' style={{backgroundImage: `url(${merchant.image})`}}/>
                <div className="title-and-subImg">
                    <img src={merchant.logo} alt="box" className='merchant-logo'/>
                    <h1>{merchant.merchant}</h1>
                </div>
            </a>
        </S.Box>
    );
};

export default MerchantBox;
