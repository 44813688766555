// antd
import {HeaderMenuStyled} from './index.styles';
import {routerPaths} from 'config/routerPaths';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

interface CustomMenuItemType {
    to: string;
    label: string;
    key: string;
}

const HeaderMenu = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const {home, shop, business, howItWorks, integration} = routerPaths;
    const items: CustomMenuItemType[] = [
        {
            label: t('header.menu.home'),
            key: 'home',
            to: home
        },
        {
            label: t('header.menu.shop'),
            key: 'shop',
            to: shop
        },
        {
            label: t('header.menu.business'),
            key: 'merchants',
            to: business
        },
        {
            label: t('header.menu.how-it-works'),
            key: 'howitworks',
            to: howItWorks
        },
        {
            //
            label: t('header.menu.integration'),
            key: 'integration',
            to: integration
        }
    ];
    const currentKey = items.find((item) => item.to === location.pathname)?.key;

    return (
        <HeaderMenuStyled mode="inline" selectedKeys={[currentKey || '']}>
            {items.map((item) => (
                <HeaderMenuStyled.Item key={item.key}>
                    <Link to={item.to}>{item.label}</Link>
                </HeaderMenuStyled.Item>
            ))}
        </HeaderMenuStyled>
    );
};

export default HeaderMenu;
