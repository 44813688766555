import * as S from './index.styles';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//swiper
import {Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

//ui
import {Col, Row} from 'antd';
//comp
import Banner from 'components/banner';
import {Button} from 'components/button';
import FeaturedStores from 'components/featured-stores';
import FeaturedProducts from 'components/featured-products';

import Title from 'components/title';
import ShopTheEasyWay from 'components/shop-the-easy-way';

// widgets
import Main from 'widgets/main';
import WannaOfferYourCustomers from 'widgets/wanna-offer-your-customers';

//common
import {useRTLSupportContext} from 'common/rtl';
import usePageApi from '../../api/page/page.api';
import {HomePageResponse} from '../../api/page/page.model';
import {useCommon} from 'context/common/common.context';

const HomePage = () => {
    const common = useCommon();
    const navigate = useNavigate();

    const qR = common.source?.widgets?.qrCode?.image?.url;

    const {isRTL} = useRTLSupportContext();
    const {t, i18n} = useTranslation();
    const [pagePayload, setPagePayload] = useState<HomePageResponse>();
    const pageApi = usePageApi();

    const fetchHomeData = useCallback(async () => {
        try {
            const response = await pageApi.getHome();

            setPagePayload(response.result);
        } catch (error) {
            console.log('error', error);
        }
    }, [pageApi]);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData, i18n.language]);

    const renderedFeaturedStoreItems = pagePayload?.widgets?.featuredStores?.map(
        (item: any, index: any) => (
            <SwiperSlide key={index}>
                <FeaturedStores image={item.image.url} title={item.title} link={item.link} />
            </SwiperSlide>
        )
    );

    const renderedFeaturedProductsItems = pagePayload?.widgets?.featuredProducts?.map(
        (item, index) => (
            <SwiperSlide key={index}>
                <FeaturedProducts item={item} />
            </SwiperSlide>
        )
    );

    const heroImage = pagePayload?.widgets.heroImage;
    const merchants = pagePayload?.widgets?.merchants;
    const easyWayCard1 = pagePayload?.widgets?.easyWayCard1;
    const easyWayCard2 = pagePayload?.widgets?.easyWayCard2;
    const easyWayCard3 = pagePayload?.widgets?.easyWayCard3;
    const easyWayHead = pagePayload?.widgets?.easyWayHead;
    const logoTitle = pagePayload?.widgets?.logoTitle;
    return (
        <Main>
            <S.HomePageStyled>
                <Banner
                    title={heroImage?.title}
                    subTitle={heroImage?.desc}
                    link={heroImage?.link}
                    image={isRTL ? heroImage?.image.url : heroImage?.image.url}
                    mobileImage={heroImage?.mobileImage?.url}
                    qr={qR}
                    qrText={heroImage?.qrText}
                />
                <section className="children">
                    <S.ShopTheEasyWay>
                        <div className="container">
                            <Title title={easyWayHead?.title} />
                            <Row gutter={[12, 12]}>
                                <Col lg={8} md={12} xs={24}>
                                    <ShopTheEasyWay
                                        image={easyWayCard1?.image?.url}
                                        title={easyWayCard1?.title}
                                        info={easyWayCard1?.description}
                                    />
                                </Col>
                                <Col lg={8} md={12} xs={24}>
                                    <ShopTheEasyWay
                                        image={easyWayCard2?.image?.url}
                                        title={easyWayCard2?.title}
                                        info={easyWayCard2?.description}
                                    />
                                </Col>
                                <Col lg={8} md={12} xs={24}>
                                    <ShopTheEasyWay
                                        image={easyWayCard3?.image?.url}
                                        title={easyWayCard3?.title}
                                        info={easyWayCard3?.description}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </S.ShopTheEasyWay>
                    <S.FeaturedStores>
                        <div className="container">
                            <Row justify="space-between" align="middle">
                                <Col lg={12} xs={24}>
                                    <Title title={logoTitle?.[0]?.title} />
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Row justify="end">
                                        <Button
                                            type="primary"
                                            className="desktop-for-btn"
                                            onClick={() => {
                                                navigate('/shop');
                                            }}
                                        >
                                            {t('widget.featuredStoresBtnText')}
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={12}
                                    slidesPerView={4}
                                    navigation
                                    breakpoints={{
                                        340: {
                                            slidesPerView: 2
                                        },
                                        768: {
                                            slidesPerView: 4
                                        }
                                    }}
                                >
                                    {renderedFeaturedStoreItems}
                                </Swiper>
                            </Row>
                            <Row justify="center">
                                <Button type="primary" className="mobile-for-btn">
                                    {t('widget.featuredStoresBtnText')}
                                </Button>
                            </Row>
                        </div>
                    </S.FeaturedStores>
                    <S.FeaturedProducts>
                        <div className="container">
                            <Row justify="space-between" align="middle">
                                <Col xs={24} lg={12}>
                                    <Title title={logoTitle?.[1]?.title} />
                                </Col>
                                <Col xs={24} lg={12}></Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={12}
                                    slidesPerView={4}
                                    navigation
                                    breakpoints={{
                                        340: {
                                            slidesPerView: 2
                                        },
                                        768: {
                                            slidesPerView: 3
                                        }
                                    }}
                                >
                                    {renderedFeaturedProductsItems}
                                </Swiper>
                            </Row>
                        </div>
                    </S.FeaturedProducts>
                    <S.MerchantsImages>
                        <div className="container">
                            <Row>
                                {merchants &&
                                    merchants.map((item, index) => (
                                        <Col xs={12} md={6} lg={4} key={index}>
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                <img src={item.logo.url} alt={item.merchantName} />
                                            </a>
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                    </S.MerchantsImages>
                    <S.WannaOfferYourCustomers>
                        <div className="container">
                            <Title
                                type="center"
                                title={t('widget.wannaOfferTitle')}
                                subTitle={t('widget.wannaOfferSubTitle')}
                            />
                            <WannaOfferYourCustomers
                                title={pagePayload?.widgets?.qrCard?.title}
                                image={pagePayload?.widgets?.qrCard?.image}
                                qr={qR}
                                smallBanner={pagePayload?.widgets.smallBanner}
                            />
                        </div>
                    </S.WannaOfferYourCustomers>
                </section>
            </S.HomePageStyled>
        </Main>
    );
};

export default HomePage;
