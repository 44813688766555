import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';

const PrivacyMobileCss = css``;

export const Privacy = styled.div`
    ${mediaQuery.lessThan('xltablet')`${PrivacyMobileCss}`}
`;

export const BeApartBanner = styled.div`
    background-color: ${color('primary.500')};
    height: 115px;
    position: relative;
    margin-bottom: 32px;
    overflow: hidden;

    &:after {
        content: '';
        width: 100%;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 770px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
        z-index: 1;
    }
`;
