import logoDark from 'assets/images/mispay-dark.svg';
import logoLight from 'assets/images/mispay-light.svg';
import { Link } from 'react-router-dom';

export interface LogoProps {
    customStyle?: React.CSSProperties;
    type: 'light' | 'dark';
}

const Logo = ({ customStyle, type }: LogoProps) => {
    const logo = {
        light: logoLight,
        dark: logoDark
    }[type];

    return (
        <Link to="/">
            <img src={logo} style={{ cursor: 'pointer', ...customStyle }} alt="mispay" />
        </Link>
    );
};

export default Logo;
