import { ThemeProps } from 'styled-components';
import { Palette, Theme } from 'types/theme';

type ColorKey = NestedKeys<Palette>;

export const color = (colorKey: ColorKey): ((props: ThemeProps<Theme>) => string) => {
    const [paletteKey, shade] = colorKey.split('.');
    // primary.500 => secondary.default

    return (props: ThemeProps<Theme>): string =>
        props.theme.colors[paletteKey as keyof Palette][shade as keyof Palette[keyof Palette]];
};
