import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './index.styles';
import { Col } from 'antd';
import HeaderMenu from 'components/header-menu';
import HeaderMobile from 'widgets/header-mobile';
import Logo from 'components/logo';
import { Button } from 'components/button';
import ChangeLanguage from 'components/change-language';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const location = useLocation();
    const [isSticky, setIsSticky] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <>
            <HeaderMobile isSticky={isSticky} />
            <S.HeaderContainer className={isSticky ? 'sticky' : ''}>
                <div className="container">
                    <S.RowStyled align="middle">
                        <Col span={5}>
                            <S.LogoMenuAuthContainer>
                                <S.LogoContainer>
                                    <Logo type={isSticky ? 'dark' : 'light'} />
                                </S.LogoContainer>
                            </S.LogoMenuAuthContainer>
                        </Col>
                        <Col span={19}>
                            <S.LogoMenuAuthContainer>
                                <S.MenuAuthContainer>
                                    <S.MenuContainer>
                                        <HeaderMenu />
                                    </S.MenuContainer>
                                    <S.AuthenticationContainer>
                                        <Button
                                            type="default"
                                            onClick={() => {
                                                window.open(`https://console.mispay.co/`, '_blank');
                                            }}
                                        >
                                            {t('header.menu.login')}
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                window.open(
                                                    `https://www.mispay.co/onboarding?lang=${i18n.language}`,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            {t('header.menu.signup')}
                                        </Button>
                                        <ChangeLanguage />
                                    </S.AuthenticationContainer>
                                </S.MenuAuthContainer>
                            </S.LogoMenuAuthContainer>
                        </Col>
                    </S.RowStyled>
                </div>
            </S.HeaderContainer>
        </>
    );
};

export default Header;
