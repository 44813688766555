import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const MobileContactUsFormCss = css`
    padding: 20px 20px;
    h1 {
        font-size: 35px;
        line-height: 35px;
    }
`;

export const ContactUsFormStyled = styled.div`
    border-radius: 30px;
    padding: 40px;
    background-color: ${color('neutral.100')};

    h1 {
        font-size: ${fonts.size.xxxxxlarge};
        line-height: ${fonts.size.xxxxxlarge};
        max-width: 450px;
    }

    .form {
        margin-top: 20px;

        .form-item {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                font-size: 18px;
                font-weight: 600;
                color: ${color('base.black')};
            }

            .input,
            .textarea {
                margin: 5px 0px;
                padding: 16px 20px 16px 20px;
                border: 1px solid #c7ced7;
                border-radius: 10px;
                //color: #C5CCD7 !important;
                resize: none;
                background-color: ${color('base.white')};

                &:focus {
                }
            }

            .textarea {
                min-height: 180px;
            }

            &.error {
                .input,
                .textarea {
                    border-color: red;
                }
            }
        }

        .ant-btn {
            margin-top: 20px;
            width: 100%;
        }
    }

    ${mediaQuery.lessThan('tablet')`${MobileContactUsFormCss}`}
`;
