import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./index.styles";
//ui
import { Row, Col } from "antd";
// widgets
import Main from "widgets/main";
// comp
import Banner from "components/banner";
import Title from "components/title";
import Partner from "components/partner";
import PaymentSolution from "components/payment-solution";
import DynamicCard from "components/dinamic-card";
import usePageApi from "../../api/page/page.api";
import { InStoreResponse } from "../../api/page/page.model";

const InStore = () => {
  const { t } = useTranslation();
  const [pagePayload, setPagePayload] = useState<InStoreResponse>();
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getInStore();

      setPagePayload(response.result);
    } catch (error) {
      console.log("error", error);
    }
  }, [pageApi]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);
  const heroImage = pagePayload?.widgets?.heroImage;
  const merchantLogos = pagePayload?.widgets?.merchantLogos;
  const numberCard = pagePayload?.widgets?.numberCards;
  const howItWorkCard = pagePayload?.widgets?.howItWorkCard;
  const mixedCard1 = pagePayload?.widgets?.mixedCard1;
  const mixedCard2 = pagePayload?.widgets?.mixedCard2[0];
  const mixedCard3 = pagePayload?.widgets?.mixedCard3[0];

  const handleBtnClick = () => {
    console.log("handleBtnClick");
  };
  return (
    <Main>
      <S.InStore>
        <Banner
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          btnText={heroImage?.buttonLabel}
          mobileImage={heroImage?.mobileImage?.url}
          link={heroImage?.link}
        />
        <section className="children">
          <div className="container">
            <div className="giants">
              <p>
                {t("instore.subTitle")}
              </p>
              <Row gutter={[24, 24]}>
                {merchantLogos?.map((item: any) => (
                  <Col xs={12} md={4}>
                    <img src={item.url} alt={item.name} />
                  </Col>
                ))}
              </Row>
            </div>
            <div className="all-about-the-numbers">
              <Title title={t("instore.itsAllAbout")} />
              <Row gutter={[24, 24]}>
                {numberCard?.map((item: any) => (
                  <Col xs={12} md={8}>
                    <Partner percentage={item.title} info={item.content} />
                  </Col>
                ))}
              </Row>
            </div>
            <div className="how-it-works">
              <Title title={t("instore.howItWorks")} />
              <Row gutter={[24, 24]}>
                {howItWorkCard?.map((item: any) => (
                  <Col xs={12} md={8}>
                    <PaymentSolution
                      image={item?.image.url}
                      title={item?.title}
                      subTitle={item?.description}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div className="dynamic-card">
              <DynamicCard
                extra={mixedCard1?.Items ? true : false}
                extraList={mixedCard1?.Items ? mixedCard1?.Items : []}
                rtl={false}
                image={mixedCard1?.image?.url}
                title={mixedCard1?.title}
                subTitle={mixedCard1?.description}
                btnText={mixedCard1?.buttonLabel}
                btnOnClick={handleBtnClick}
              />
            </div>
            <div className="dynamic-card">
              <DynamicCard
                rtl
                image={mixedCard2?.image?.url}
                title={mixedCard2?.title}
                subTitle={mixedCard2?.description}
                btnText={mixedCard2?.buttonLabel}
                btnOnClick={handleBtnClick}
              />
            </div>
            <div className="dynamic-card">
              <DynamicCard
                rtl={false}
                image={mixedCard3?.image?.url}
                title={mixedCard3?.title}
                subTitle={mixedCard3?.description}
                btnText={mixedCard3?.buttonLabel}
                btnOnClick={handleBtnClick}
              />
            </div>
          </div>
        </section>
      </S.InStore>
    </Main>
  );
};

export default InStore;
