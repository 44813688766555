import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const MainMobileCss = css`
    .children {
        margin:0 15px;
    }
`;

export const MainStyled = styled.main`
    position: relative;
    ${mediaQuery.lessThan('tablet')`${MainMobileCss}`}
`;