import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';

const ShariyaComplianceMobileCss = css`
    .ant-tabs {
        margin-top:20px !important;
        margin-bottom:10px !important;
        .ant-tabs-nav-list {
            overflow:hidden;
            .ant-tabs-tab {
                &:nth-child(2) {
                    margin-left:0 !important;
                }
            }
        }
    }
`

export const IntegrationStyled = styled.div`
    .explore-our {
        margin-top:60px;
        .ant-tabs {
            margin:40px 0px;
        }
    }
    ${mediaQuery.lessThan('xltablet')`${ShariyaComplianceMobileCss}`}
`