import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

export const HeroImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 50%;
    translate: 0 -45%;
    z-index: 2;
    width: 500px;
    height: 500px;
`;

const DesktopBannerCss = css`
    height: 500px;

    ${HeroImageContainer} {
        width: 300px;
        height: 300px;
    }
`;
const TabletBannerCss = css`
    height: 400px;

    ${HeroImageContainer} {
        width: 250px;
        height: 250px;
    }
`;
const MobileBannerCss = css`
    height: 268px;
    padding: 0px 20px;

    ${HeroImageContainer} {
        width: 180px;
        height: 180px;
    }

    .qr-code-container {
        .qr-text {
            font-size: 12px;
            margin-left: 15px;
            max-width: 150px;
            color: white;
        }
    }
    .link {
        padding: 6px 15px !important;
        line-height: 24px !important;
        font-size: 12px !important;
    }
    .ant-btn {
        span {
            line-height: 25px;
        }
    }
`;

export const BannerStyled = styled.article`
    height: 770px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: ${color('primary.500')};

    .qr-code-container {
        display: flex;
        align-items: center;
        .qr-text {
            font-size: 20px;
            margin-left: 15px;
            max-width: 250px;
            color: white;
        }
    }

    .gradient {
        width: 100%;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
        z-index: 1;
    }
    ${mediaQuery.lessThan('desktop')`${DesktopBannerCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerCss}`}
`;

export const HeroImage = styled.img`
    // position: absolute;
    max-width: 100%; /* Ensure the image doesn't overflow the container */
    height: auto;
    inset: 0px;
    color: transparent;
    object-fit: contain;
`;

export const Info = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .link {
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 30px;
        border-radius: 60px;
        line-height: 30px;
        transition: all 0.5s ease;
        background-color: ${color('base.white')};
        color: ${color('primary.500')};
        &:hover {
            transition: all 0.5s ease;
            opacity: 0.8;
        }
    }
`;

const DesktopBannerTitleCss = css`
    font-size: ${fonts.size.xxxxlarge};
    line-height: 52px;
`;

const TabletBannerTitleCss = css`
    font-size: 30px;
    line-height: 42px;
    max-width: 100%;
`;

const MobileBannerTitleCss = css`
    font-size: 18px;
    line-height: 25px;
    margin-top: 70px;
    max-width: 250px;
`;

export const Title = styled.h1`
    margin: 0;
    margin-top: 30px;
    line-height: 75px;
    font-size: ${fonts.size.xxxxxxlarge};
    //max-width:600px;
    color: ${color('base.white')};
    ${mediaQuery.lessThan('desktop')`${DesktopBannerTitleCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerTitleCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerTitleCss}`}
`;

const DesktopBannerSubTitleCss = css`
    font-size: 20px;
`;
const TabletBannerSubTitleCss = css`
    font-size: ${fonts.size.large};
    max-width: 100%;
`;
const MobileBannerSubTitleCss = css`
    font-size: 10px;
    margin-bottom: 10px;
    max-width: 200px;
`;
export const Subtitle = styled.h2`
    font-weight: 300;
    font-size: ${fonts.size.xxlarge};
    color: ${color('base.white')};
    max-width: 400px;
    ${mediaQuery.lessThan('desktop')`${DesktopBannerSubTitleCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerSubTitleCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerSubTitleCss}`}
`;

const MobileQrCodeCss = css`
    max-width: 50px;
    max-height: 50px;
    padding: 6px;
    border-radius: 12px;
    img {
        border-radius: 8px;
        max-width: 50px;
        max-height: 50px;
    }
`;

export const QRCode = styled.div`
    max-width: 80px;
    max-height: 80px;
    display: block;
    border-radius: 20px;
    padding: 12px;
    border: 1px solid ${color('base.white')};
    img {
        max-width: 80px;
        border-radius: 12px;
        display: block;
    }

    ${mediaQuery.lessThan('tablet')`${MobileQrCodeCss}`}
`;
