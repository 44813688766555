import * as S from './index.styles';
import nl2br from "react-nl2br";
import {ImageCard} from 'api/page/page.model';

interface ShopSplitManageProps extends ImageCard {
    isBig?: boolean;
}

const ShopSplitManage = ({id, image, title, description, label, isBig}: ShopSplitManageProps) => {
    return (
        <S.ShopSplitManage key={id} style={{
            backgroundImage: `url(${image})`,
            height: isBig ? '574px' : '274px',
        }}>
            <h1>{nl2br(title)}</h1>
            <h2 dangerouslySetInnerHTML={{__html: description}}></h2>
            {label && <span>{nl2br(label)}</span>}
        </S.ShopSplitManage>
    )
}

export default ShopSplitManage;