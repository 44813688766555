import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import ellipseOne from 'assets/images/homepage/ellipse-1.svg';
import ellipseTwo from 'assets/images/homepage/ellipse-2.svg';
import ellipseThree from 'assets/images/homepage/ellipse-3.svg';
import { color } from 'common/utils/pickColor';

export const HomePageStyled = styled.div`
    background-color: ${color('base.white')};
`;

const ShopTheEasyWayMobileCss = css`
    margin-top: 20px;
    .ant-row {
        margin-top: 20px;
    }
    &:before {
        display: none;
    }
`;

export const ShopTheEasyWay = styled.div`
    position: relative;
    margin: 40px 0px;
    /*
    &:before {
        content:'';
        position: absolute;
        display: block;
        width:100%;
        height:100%;
        left:-50px;
        top:0;
        background-size: contain;
        background-repeat: no-repeat;
        background-image:url(${ellipseOne});
    }
    */
    h1 {
        text-align: center;
    }
    .ant-row {
        margin-top: 30px;
    }
    ${mediaQuery.lessThan('tablet')`${ShopTheEasyWayMobileCss}`}
`;

const FeaturedStoresMobileCss = css`
    margin-top: 20px;
    > .ant-row {
        margin-top: 20px;
    }
    &:after {
        display: none !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
    .desktop-for-btn {
        display: none;
    }
    .mobile-for-btn {
        display: block;
    }
    h1 {
        text-align: center;
    }
`;

export const FeaturedStores = styled.div`
    position: relative;
    z-index: 2;

    .container {
        position: relative;
        z-index: 2;
    }

    .mobile-for-btn {
        display: none;
        margin-top: 20px;
    }
    /*
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 400px;
        height: 400px;
        right: 0;
        top: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(${ellipseTwo});

        z-index: 0;
    }
    */
    .ant-row {
        position: relative;

        &.ant-row-space-between {
            margin-bottom: 30px;
        }
    }

    .swiper {
        position: static;

        .swiper-button-prev {
            width: 40px;
            height: 40px;
            top: 70px;
            background-color: ${color('primary.500')};

            &:after {
                left: -1px;
            }
        }

        .swiper-button-next {
            width: 38px;
            height: 38px;
            top: 120px;
            border: 1px solid ${color('primary.500')};

            &:after {
                left: 2px;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            border-radius: 50%;
            left: -50px;
            right: inherit !important;

            &:after {
                position: relative;
                font-size: 17px;
                font-weight: bold;
                color: ${color('base.white')};
            }
        }
        .swiper-button-next::after {
            color: ${color('primary.500')};
        }

        .swiper-button-disabled {
            opacity: 1 !important;
        }
    }

    ${mediaQuery.lessThan('xltablet')`${FeaturedStoresMobileCss}`}
`;

const FeaturedProductsMobileCss = css`
    margin-top: 40px;
    .ant-row:first-child {
        flex-direction: column;
        .ant-col:last-child {
            margin-top: 10px;
            .ant-select {
                width: 266px !important;
                height: 44px !important;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
`;

export const FeaturedProducts = styled.div`
    margin: 40px 0px;
    .ant-row {
        position: relative;
        &.ant-row-space-between {
            margin-bottom: 30px;
        }
    }
    .swiper {
        position: static;
        .swiper-button-prev {
            width: 40px;
            height: 40px;
            top: 70px;
            background-color: ${color('primary.500')};
            &:after {
                left: -1px;
            }
        }
        .swiper-button-next {
            width: 38px;
            height: 38px;
            top: 120px;
            border: 1px solid ${color('primary.500')};
            &:after {
                left: 2px;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            border-radius: 50%;
            left: -50px;
            right: inherit !important;
            &:after {
                position: relative;
                font-size: 17px;
                font-weight: bold;
                color: ${color('base.white')};
            }
        }
        .swiper-button-next::after {
            color: ${color('primary.500')};
        }
    }
    .ant-select {
        height: 44px;
        .ant-select-selector {
            border-radius: 10px;
            padding: 0px 15px;
            border: none;
            box-shadow: none !important;
            background-color: #dbe2ec;
            .ant-select-selection-search {
            }
            .ant-select-selection-placeholder {
                font-size: 18px;
                font-weight: 400;
                color: #14151e;
            }
        }
        .ant-select-arrow {
            top: 55%;
            .mispay-icon {
                font-size: 12px;
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${FeaturedProductsMobileCss}`}
`;

const WannaOfferYourCustomersMobileCss = css`
    margin: 0px 15px 40px 15px;
    &:before {
        display: none !important;
    }
`;

export const WannaOfferYourCustomers = styled.div`
    margin-bottom: 40px;
    position: relative;
    /*
    &:before {
        content:'';
        position: absolute;
        display: block;
        width:400px;
        height:100%;
        left:-50px;
        top:0;
        background-size: contain;
        background-repeat: no-repeat;
        background-image:url(${ellipseThree});
    }
    */
    ${mediaQuery.lessThan('xltablet')`${WannaOfferYourCustomersMobileCss}`}
`;

export const MerchantsImages = styled.div`
    .ant-row {
        align-items: center;
        .ant-col {
            text-align: center;
            a {
                display: block;
                transition: all 0.5s ease;
                &:hover {
                    transition: all 0.5s ease;
                    opacity: 0.6;
                }
            }
        }
    }
`;
