import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from "./index.styles";
import {Row, Col} from "antd";
import Main from "widgets/main";
import Banner from "components/banner";
import Title from "components/title";
import TabMenu from "components/tab-menu";
import BasicCard from "components/basic-card";
import usePageApi from "../../api/page/page.api";
import {IntegrationResponse} from "../../api/page/page.model";

const Integration = () => {
    const {t} = useTranslation();
    const [pagePayload, setPagePayload] = useState<IntegrationResponse>();
    const [activeTab, setActiveTab] = useState("Integration");
    const pageApi = usePageApi();

    const fetchHomeData = useCallback(async () => {
        try {
            const response = await pageApi.getIntegration();
            setPagePayload(response.result);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData]);

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const heroImage = pagePayload?.widgets?.heroImage;
    const integrationList = pagePayload?.widgets?.integration?.cards;
    const servicesList = pagePayload?.widgets?.services?.cards;

    const renderCards = activeTab === "1" ? integrationList : servicesList;
    return (
        <Main>
            <S.IntegrationStyled>
                <Banner
                    title={heroImage?.title}
                    subTitle={heroImage?.desc}
                    image={heroImage?.image.url}
                    btnText={heroImage?.buttonLabel}
                    mobileImage={heroImage?.mobileImage?.url}
                    link={heroImage?.link}
                />
                <section className="children">
                    <div className="container">
                        <div className="explore-our">
                            <Title title={t("integration.exploreOur")} type="center"/>
                            <TabMenu
                                tabContent={[
                                    {title: t("integration.tabMenu.title1"), content: ""},
                                    {title: t("integration.tabMenu.title2"), content: ""}
                                ]}
                                activeTab={activeTab}
                                onTabChange={handleTabChange}
                                type="overlay"
                            />
                            <Row gutter={[24, 24]}>
                                {renderCards?.map((item: any) => (
                                    <Col key={item.id} xs={24} md={12}>
                                        <BasicCard
                                            icon={item.icon}
                                            title={item.title}
                                            subTitle={item.content}
                                            btnText={t("integration.learnMore")}
                                            link={item.link}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </section>
            </S.IntegrationStyled>
        </Main>
    );
};

export default Integration;
