import {useCallback, useEffect, useState} from "react";
import * as S from "./index.styles";
import {useTranslation} from "react-i18next";

//ui
import {Col, Row} from "antd";

//comp
import {Button} from "components/button";
import BannerDynamic from "components/banner-dynamic";
// widgets
import Main from "widgets/main";
import Title from "components/title";

import usePageApi from "../../api/page/page.api";
import {Category, Merchant, ShopDetailResponse} from "../../api/page/page.model";
import MerchantBox from "components/box";

const Shop = () => {
    const {t, i18n} = useTranslation();
    const [shopAll, setShopAll] = useState<Merchant[]>([]);
    const [shopDetail, setshopDetail] = useState<ShopDetailResponse>();
    const [shopCategory, setShopCategory] = useState<Category[]>([]);
    const [filteredShopAll, setFilteredShopAll] = useState<any>(null);
    const [showAllCategories, setShowAllCategories] = useState<{ [key: string]: boolean }>({});

    const pageApi = usePageApi();

    const fetchShopAllData = useCallback(async () => {
        try {
            const response = await pageApi.getShopAll();
            setShopAll(response.result as unknown as Merchant[]);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    const fetchShopDetailData = useCallback(async () => {
        try {
            const response = await pageApi.getShopDetail();
            setshopDetail(response.result);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    const fetchShopCategoryData = useCallback(async () => {
        try {
            const response = await pageApi.getCategory();
            setShopCategory(response.result as unknown as Category[]);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    const heroImage = shopDetail?.widgets?.heroImage;

    const onSearch = (searchTerm: string) => {
        if (!searchTerm.trim()) {
            setFilteredShopAll(null);
            return;
        }
        const filteredData = shopAll.filter((shop: any) =>
            shop.merchant.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
        setFilteredShopAll(filteredData);
    }

    const onCategoryChange = (categoryKey: string) => {
        if (!categoryKey.trim()) {
            setFilteredShopAll(null);
            return;
        }
        const filteredData = shopAll.filter((shop: any) =>
            shop.categories.some((category: any) => category.id === parseInt(categoryKey))
        );
        setFilteredShopAll(filteredData);
    };

    useEffect(() => {
        fetchShopAllData();
        fetchShopDetailData();
        fetchShopCategoryData();
    }, [fetchShopAllData, fetchShopDetailData, fetchShopCategoryData]);


    const onSeeAllClick = (categoryKey: string) => {
        setShowAllCategories(prevState => ({
            ...prevState,
            [categoryKey]: true,
        }));
    };
    return (
        <Main>
            <S.Shop>
                <BannerDynamic
                    title={heroImage?.title}
                    subTitle={heroImage?.desc}
                    image={heroImage?.image?.url}
                    mobileImage={heroImage?.mobileImage?.url}
                    items={shopCategory}
                    onSearch={onSearch}
                    onCategoryChange={onCategoryChange}
                    category
                />
                <section className="children">
                    <div className="container">
                        {shopCategory.map((category: any) => {
                            const categoryShops = filteredShopAll
                                ? filteredShopAll.filter((shop: any) =>
                                    shop.categories.some((cat: any) => cat.id === category.id)
                                )
                                : shopAll.filter((shop: any) =>
                                    shop.categories.some((cat: any) => cat.id === category.id)
                                );

                            if (categoryShops.length === 0) return null;

                            const displayedShops = showAllCategories[category.key]
                                ? categoryShops
                                : categoryShops.slice(0, 8);

                            return (
                                <div key={category.id} className={`box ${category.key}`}>
                                    <Title title={category[i18n.language] || category.en}/>
                                    <Row gutter={[20, 20]}>
                                        {displayedShops.map((item: Merchant, itemIndex: number) => (
                                            <Col key={itemIndex} xs={12} md={6}>
                                                <MerchantBox merchant={item}/>
                                            </Col>
                                        ))}
                                    </Row>
                                    {!showAllCategories[category.key] && categoryShops.length > 8 && (
                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                            <Button type="primary"
                                                    onClick={() => onSeeAllClick(category.key)}>{t("shop.btn")}</Button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </section>
            </S.Shop>
        </Main>
    );
};
export default Shop;
