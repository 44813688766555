import { useSearchParams } from 'react-router-dom';

const useLayoutVisibility = () => {
    const [searchParams] = useSearchParams();

    return !coerceStringToBoolean(searchParams.get('noBaseLayout'));
};

const coerceStringToBoolean = (value: unknown) => {
    return typeof value === 'string' && value.toLowerCase() === 'true';
};

export default useLayoutVisibility;
