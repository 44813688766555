import * as S from "./index.styles";
import { SeamlessInterface } from "common/interface/general";

const Seamless = ({ title, image, link }: SeamlessInterface) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <S.Seamless>
        <div className="image">
          <img src={image} alt="seamless" />
        </div>
        <div className="title">{title}</div>
      </S.Seamless>
    </a>
  );
};

export default Seamless;
