export interface FontSize {
    xxxxxxlarge: string;
    xxxxxlarge: string;
    xxxxlarge: string;
    xxxlarge: string;
    xxlarge: string;
    xlarge: string;
    large: string;
    medium: string;
    small: string;
    xSmall: string;
    xxSmall: string;
}

interface FontWeight {
    bold: string;
    semibold: string;
    medium: string;
    regular: string;
    light: string;
}

interface Font {
    family: string;
    size: FontSize;
    weight: FontWeight;
}

const fontSize: FontSize = {
    xxxxxxlarge: '64px',
    xxxxxlarge: '50px',
    xxxxlarge: '40px',
    xxxlarge: '32px',
    xxlarge: '24px',
    xlarge: '20px',
    large: '16px',
    medium: '14px',
    small: '12px',
    xSmall: '10px',
    xxSmall: '8px',
};
const fontWeight: FontWeight = {
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400',
    light: '300',
};

export const fonts: Font = {
    family: 'Roboto,Arial',
    size: fontSize,
    weight: fontWeight,
};
