import { useTranslation } from "react-i18next";
import { Button } from "components/button";

const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const changeLanguage = async () => {
    const languageToSet = language === "ar" ? "en" : "ar";
    localStorage.setItem("@preferredLanguage", languageToSet);
    await i18n.changeLanguage(languageToSet);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <Button type="link" onClick={changeLanguage}>
      {i18n.language === "ar"
        ? t("header.menu.english")
        : t("header.menu.arabic")}
    </Button>
  );
};

export default ChangeLanguage;
