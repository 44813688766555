import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {CommonWidgetsResponse} from "../../api/page/page.model";
import usePageApi from "../../api/page/page.api";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

export interface CommonContextType {
    source: CommonWidgetsResponse;
}

interface CommonProviderProps {
    children: React.ReactNode;
}

const CommonContext = createContext<CommonContextType>({} as CommonContextType);

export const CommonProvider = ({children}: CommonProviderProps) => {
    const pageApi = usePageApi();
    const {i18n} = useTranslation();
    const [source, setSource] = useState<CommonWidgetsResponse>(
        {} as CommonWidgetsResponse
    );

    const fetchCommon = useCallback(async () => {
        // Fetch common data
        const res = await pageApi.getCommon();

        setSource({
            widgets: res.result.widgets,
        });
    }, [pageApi, setSource]);

    useEffect(() => {
        fetchCommon();
    }, [i18n.language, fetchCommon]);

    return (
        <CommonContext.Provider
            value={{
                source,
            }}
        >
            <Helmet>
                <title>{source.widgets?.SEO?.title || 'MIS Pay'}</title>
                {source.widgets?.SEO?.description &&
                    <meta name="description" content={source.widgets?.SEO?.description}/>}
                {source.widgets?.SEO?.keywords && <meta name="keywords" content={source.widgets?.SEO?.keywords}/>}

            </Helmet>
            {children}
        </CommonContext.Provider>
    );
};

export const useCommon = () => {
    return useContext(CommonContext);
};
