import * as S from "./index.styles";
import {useTranslation} from "react-i18next";
import GetYourImg from "assets/images/widgets/wanna-offer/get-your-personal-image.svg";
import Split from "assets/images/widgets/wanna-offer/split-in-up-to-payment.svg";


//ui
import {Col, Row} from "antd";
import {Image, SmallBanner} from "../../api/page/page.model";

interface WannaOfferYourCustomersProps {
    title?: string;
    image?: Image;
    qr?: string;
    smallBanner?: SmallBanner;
}

const WannaOfferYourCustomers = ({
                                     title,
                                     image,
                                     qr,
                                     smallBanner
                                 }: WannaOfferYourCustomersProps) => {
    const {t} = useTranslation();
    return (
        <S.WannaOfferYourCustomers>
            <Row gutter={[32, 32]}>
                <Col xs={24} lg={12} className="get-your-personal">
                    <h1>{t("widget.wannaOfferCard1Text")}</h1>
                    <img src={GetYourImg} alt="get-your-personal"></img>
                </Col>
                <Col xs={24} lg={12} className="split-and-qr">
                    <Row>
                        <Col span={24} className="split">
                            <h1>
                                {t("widget.wannaOfferCard2Title")} <br/>
                                <strong>{t("widget.wannaOfferCard2SubTitle")}</strong>
                            </h1>
                            <img src={Split} alt="split"/>
                        </Col>
                        <Row gutter={[32, 32]} className="only-and-pc">
                            <Col xs={24} lg={12} className="only">
                                <img src={qr} alt="qr"/>
                                <span>{title}</span>
                            </Col>
                            <Col xs={24} lg={12} className="pc">
                                {smallBanner?.image?.url && <img src={smallBanner?.image?.url} alt="pc"/>}
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </S.WannaOfferYourCustomers>
    );
};

export default WannaOfferYourCustomers;
