import { createGlobalStyle } from "styled-components";
import { fonts } from 'common/theme/font';

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montez&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  body {
    margin: 0;
    font-family: "Sharp Grotesk" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
    overflow-x: hidden
  }
  html {
    overflow-x: hidden
  }
  #root {
    overflow-x: hidden
}

  .ant-steps, .ant-dropdown, [class*="ant-row"], [class*="ant-col"], :root {
    font-family: "Sharp Grotesk" !important;
  }

  .container {
    max-width: 1200px;
    margin:0 auto;
  }

  ul,li {
    list-style-type: none;
  }
  .container-fluid {
    padding-left:15px;
    padding-right:15px;
  }
  @media (max-width: 576px) {
      .container {
          max-width:100%;
      }
  }
  @media (min-width: 576px) {
      .container {
          max-width:540px
      }
  }

  @media (min-width: 768px) {
      .container {
          max-width:720px
      }
  }

  @media (min-width: 991px) {
      .container {
          max-width:960px
      }
  }

  @media (min-width: 1200px) {
      .container {
          max-width:1200px
      }
  }
  .app-svg-sprite {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
  }
  .app-icon {
    fill: white;
  }
  .error-message {
    margin-top: 10px;
    span {
      font-size: ${fonts.size.large};
      color: #FF5656;
      font-weight: normal;
    }
  }

  ul {
    margin:0;
    padding:0;
  }
  ::-webkit-scrollbar-track
  {
    border-radius: 0;
  }

  ::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #c5c5c5;
  }
  
`;
