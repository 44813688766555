import { Menu } from 'antd';
import { color } from 'common/utils/pickColor';
import styled from 'styled-components';
// import { fonts } from 'common/theme/font';

export const HeaderMenuStyled = styled(Menu)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    border-inline: none !important;
    margin: 0px 30px 0px 0px;

    .ant-menu-item {
        margin: 0px 10px !important;
        padding: 0 10px !important;
        width: inherit !important;
        display: block !important;
        text-overflow: inherit !important;
        overflow: inherit !important;
        font-size: 16px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 0px;
        border-bottom: 2px solid transparent;

        &-selected {
            background-color: inherit !important;
            border-color: ${color('base.white')};
        }

        .ant-menu-title-content {
            a {
                color: ${color('base.white')};

                &:before {
                    display: none;
                }
            }
        }

        &:hover {
            background-color: transparent !important;
            border-color: ${color('base.white')};

            .ant-menu-title-content {
            }
        }
    }
`;
