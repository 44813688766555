import * as S from "./index.styles";
import {useCallback, useEffect, useState} from "react";

//ui
import {Row, Col} from "antd";

//comp
import Banner from "components/banner";
import ShopSplitManage from "components/shop-split-manage";

// widgets
import Main from "widgets/main";
import ShoppingGameOn from 'widgets/shopping-game-on'
import EnjoyShopping from "widgets/enjoy-shopping";
import usePageApi from "../../api/page/page.api";
import {HowItWorksPageResponse} from "../../api/page/page.model";

// common
import {useCommon} from "context/common/common.context";


const HowItWorks = () => {
    const common = useCommon();
    const qR = common.source?.widgets?.qrCode?.image?.url;

    const [pagePayload, setPagePayload] = useState<HowItWorksPageResponse>();
    const pageApi = usePageApi();

    const fetchHomeData = useCallback(async () => {
        try {
            const response = await pageApi.getHowItWorks();

            setPagePayload(response.result);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData]);

    const heroImage = pagePayload?.widgets?.heroImage;
    const bigCard = pagePayload?.widgets.bigCard;
    const downloadAppCard = pagePayload?.widgets.downloadAppCard;
    const shoppingFlow = pagePayload?.widgets.shoppingFlow;
    const smallCard1 = pagePayload?.widgets.smallCard1;
    const smallCard2 = pagePayload?.widgets.smallCard2;
    return (
        <Main>
            <S.HowItWorks>
                <Banner
                    title={heroImage?.title}
                    subTitle={heroImage?.desc}
                    image={heroImage?.image.url}
                    mobileImage={heroImage?.mobileImage?.url}
                    qr={qR}
                    qrText={heroImage?.qrText}

                />
                <section className="children">
                    <div className="container">
                        <Row className="shop-split-manage-row" gutter={[24, 24]}>
                            <Col xs={24} md={12} className="left-side">
                                <ShopSplitManage
                                    id={bigCard?.id}
                                    isBig={true} image={bigCard?.image.url}
                                    title={bigCard?.title || ''}
                                    description={bigCard?.description || ''}
                                    label={bigCard?.label || ''}
                                />
                            </Col>
                            <Col xs={24} md={12} className="right-side">
                                <Row gutter={[24, 24]}>
                                    <Col md={24} xs={24}>
                                        <ShopSplitManage
                                            id={smallCard1?.id}
                                            image={smallCard1?.image.url}
                                            title={smallCard1?.title || ''}
                                            description={smallCard1?.description || ''}
                                            label={smallCard1?.label || ''}
                                        />
                                    </Col>
                                    <Col md={24} xs={24}>
                                        <ShopSplitManage
                                            id={smallCard2?.id}
                                            image={smallCard2?.image.url}
                                            title={smallCard2?.title || ''}
                                            description={smallCard2?.description || ''}
                                            label={smallCard2?.label || ''}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="enjoy-shopping">
                            <EnjoyShopping shoppingFlow={shoppingFlow}/>
                        </div>
                        <ShoppingGameOn qr={qR} downloadAppCard={downloadAppCard}/>
                    </div>
                </section>
            </S.HowItWorks>
        </Main>
    );
};
export default HowItWorks;
