import * as S from "./index.styles";
import nl2br from "react-nl2br";
import { ShopTheEasyWayInterface } from "common/interface/home";

const ShopTheEasyWay = ({ image, title, info }: ShopTheEasyWayInterface) => {
  return (
    <S.ShopTheEasyWayStyled>
      <div
        style={{
          backgroundImage: `url(${image})`,
        }}
        className="image"
      ></div>
      <h2 className="title">{nl2br(title)}</h2>
      <p className="info" dangerouslySetInnerHTML={{ __html: info }}></p>
    </S.ShopTheEasyWayStyled>
  );
};

export default ShopTheEasyWay;
