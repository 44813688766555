import nl2br from "react-nl2br";
import * as S from "./index.styles";

const Title = ({ fontFamily, size,type, title, subTitle }: any) => {
  const sizeClassName = size ? `size-${size}` : '';
  const fontClassName = fontFamily ? `font-${fontFamily}` : '';
  return (
    <>
      <S.Container className={`${sizeClassName} ${fontClassName} ${type}`}>
      {title && <S.Title>{nl2br(title)}</S.Title>}
      {subTitle && <S.SubTitle>{nl2br(subTitle)}</S.SubTitle>}
      </S.Container>
    </>
  );
};

export default Title;
