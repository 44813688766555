import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as S from "./index.styles";
//ui
import {Col, Row} from "antd";

//comp
import Banner from "components/banner";
import Partner from "components/partner";
import PaymentSolution from "components/payment-solution";
import Seamless from "components/seamless";

// widgets
import Main from "widgets/main";
import Title from "components/title";

import usePageApi from "../../api/page/page.api";
import {MerchantsResponse} from "../../api/page/page.model";

const Merchants = () => {
    const {t} = useTranslation();
    const [pagePayload, setPagePayload] = useState<MerchantsResponse>();
    const pageApi = usePageApi();

    const fetchHomeData = useCallback(async () => {
        try {
            const response = await pageApi.getMerchants();
            setPagePayload(response.result);
        } catch (error) {
            console.log("error", error);
        }
    }, [pageApi]);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData]);
    const heroImage = pagePayload?.widgets?.heroImage;
    return (
        <Main>
            <S.Merchants>
                <Banner
                    title={heroImage?.title}
                    subTitle={heroImage?.desc}
                    image={heroImage?.image.url}
                    btnText={heroImage?.buttonLabel}
                    mobileImage={heroImage?.mobileImage?.url}
                    link={heroImage?.link}
                />
                <section className="children">
                    <div className="container">
                        <div className="over">
                            <Title type="center" subTitle={t("business.title")}/>
                            <Row className="image-list">
                                {pagePayload?.widgets?.bestOfMerchants &&
                                    pagePayload.widgets.bestOfMerchants.map((item, index) => (
                                        <Col xs={24} md={6} lg={4} key={index}>
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                <img src={item?.image?.url || ""} alt={item.title}/>
                                            </a>
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                        <div className="growth-partner">
                            <Title title={pagePayload?.widgets?.subHead1?.title}/>
                            <Row gutter={[20, 20]}>
                                {pagePayload?.widgets?.numberCards &&
                                    pagePayload.widgets.numberCards.map((item, index) => {
                                        return (
                                            <Col xs={24} lg={8} key={index}>
                                                <Partner percentage={item.title} info={item.content}/>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </div>
                        <div className="how-it-works">
                            <Title title={pagePayload?.widgets?.subHead2?.title} size="large"/>
                            <Row gutter={[24, 24]}>
                                {pagePayload?.widgets?.howItWorksFlow &&
                                    pagePayload.widgets.howItWorksFlow.map((item, index) => {
                                        return (
                                            <Col xs={12} lg={8}>
                                                <PaymentSolution
                                                    image={item.image?.url || ""}
                                                    title={item?.title || ""}
                                                    subTitle={item?.description || ""}
                                                />
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </div>
                    </div>
                </section>
                <div className="container-fluid seamless">
                    <div className="container">
                        <Title title={t("business.seamlessIntegration")}/>
                        <Row gutter={[12, 12]}>
                            {pagePayload?.widgets?.platforms &&
                                pagePayload.widgets.platforms.map((item, index) => {
                                    return (
                                        <Col xs={12} md={6} lg={4}>
                                            <Seamless
                                                image={item.image?.url || ""}
                                                title={item?.title || ""}
                                                link={item?.link || "#"}
                                            />
                                        </Col>
                                    );
                                })}
                        </Row>
                    </div>
                </div>
            </S.Merchants>
        </Main>
    );
};
export default Merchants;
