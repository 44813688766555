import * as S from "./index.styles";
import {useTranslation} from "react-i18next";
import {Formik, Form, Field} from "formik";
//comp
import Title from "components/title";
import {Button} from "components/button";
import usePageApi from "../../../api/page/page.api";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {useCallback, useState} from "react";

interface ContactFormValues {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const validate = (values: ContactFormValues) => {
    const errors: Partial<ContactFormValues> = {};

    if (!values.name) {
        errors.name = "Required";
    }
    if (!values.email) {
        errors.email = "Required";
    }
    if (!values.subject) {
        errors.subject = "Required";
    }
    if (!values.message) {
        errors.message = "Required";
    }

    return errors;
};

const ContactUsForm = () => {
    const {t} = useTranslation();
    const {submitContactForm} = usePageApi()
    const [recaptchaToken, setRecaptchaToken] = useState('')

    const onChangeReCaptcha = useCallback((token: any) => {
        console.log("Captcha value:", token);
        setRecaptchaToken(token);
    }, [])

    return (
        <S.ContactUsFormStyled>
            <Title title={t("contactus.form.title")}/>
            <div className="form">
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        subject: "",
                        message: "",
                    }}
                    validate={validate}
                    onSubmit={async (values, actions) => {
                        console.log({values, actions});
                        await submitContactForm({...values, recaptchaToken});
                        actions.resetForm();
                        actions.setSubmitting(false);
                    }}
                >
                    {({errors, touched}) => (
                        <Form>
                            <div className={`form-item ${errors.name && touched.name ? 'error' : ''}`}>
                                <label htmlFor="name" className="label">
                                    {t("contactus.form.label1")}
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    className={`input ${errors.name && touched.name ? 'error-border' : ''}`}
                                    placeholder={t("contactus.form.placeholder1")}
                                />
                            </div>
                            <div className={`form-item ${errors.email && touched.email ? 'error' : ''}`}>
                                <label htmlFor="email" className="label">
                                    {t("contactus.form.label2")}
                                </label>
                                <Field
                                    id="email"
                                    name="email"
                                    className={`input ${errors.email && touched.email ? 'error-border' : ''}`}
                                    placeholder={t("contactus.form.placeholder2")}
                                />
                            </div>
                            <div className={`form-item ${errors.subject && touched.subject ? 'error' : ''}`}>
                                <label htmlFor="subject" className="label">
                                    {t("contactus.form.label3")}
                                </label>
                                <Field
                                    id="subject"
                                    name="subject"
                                    className={`input ${errors.subject && touched.subject ? 'error-border' : ''}`}
                                    placeholder={t("contactus.form.placeholder3")}
                                />
                            </div>
                            <div className={`form-item ${errors.message && touched.message ? 'error' : ''}`}>
                                <label htmlFor="message" className="label">
                                    {t("contactus.form.label4")}
                                </label>
                                <Field
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    className={`textarea ${errors.message && touched.message ? 'error-border' : ''}`}
                                    placeholder={t("contactus.form.placeholder4")}
                                />
                            </div>

                            <ReCAPTCHA
                                style={{margin: '20px 0 0'}}
                                sitekey="6Lf7huspAAAAAPw4UPpAXavOd0IuJM2IN5oI7mK7"
                                onChange={onChangeReCaptcha}
                            />

                            <Button htmlType="submit" type="primary" disabled={!recaptchaToken}>
                                {t("contactus.form.btn")}
                            </Button>

                        </Form>
                    )}
                </Formik>
            </div>
        </S.ContactUsFormStyled>
    );
};

export default ContactUsForm;
