import styled from 'styled-components';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

export const TabMenuStyled = styled.div`
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
                .ant-tabs-ink-bar {
                    background-color: ${color('primary.500')};
                }
                .ant-tabs-tab {
                    .ant-tabs-tab-btn {
                        font-size: ${fonts.size.xxxlarge};
                        font-weight: 500;
                        color: ${color('neutral.400')};
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: ${color('primary.500')};
                        }
                    }
                    &:hover {
                        color: ${color('primary.500')};
                    }
                }
            }
        }
    }
    .overlay {
        .ant-tabs-nav {
            &:before {
                border-bottom: none;
            }
            .ant-tabs-nav-wrap {
                justify-content: center;
                .ant-tabs-nav-list {
                    border-radius: 65px;
                    padding: 2px 10px;
                    background-color: #f3f3f3;
                    .ant-tabs-tab {
                        border-radius: 65px;
                        padding: 10px 35px;
                        .ant-tabs-tab-btn {
                            font-size: 19px;
                        }
                        &-active {
                            background-color: ${color('primary.500')};
                            .ant-tabs-tab-btn {
                                color: ${color('base.white')};
                            }
                        }
                    }
                    .ant-tabs-ink-bar {
                        display: none;
                    }
                }
            }
        }
    }
`;
