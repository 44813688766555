import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import * as S from './index.styles';
import { Row, Col } from 'antd';
import Title from 'components/title';

const EnjoyShopping = ({ shoppingFlow }: any) => {
    const { t } = useTranslation();
    const [hoveredItem, setHoveredItem] = useState<string>('');

    useEffect(() => {
        if (shoppingFlow && shoppingFlow.length > 0) {
            setHoveredItem(shoppingFlow[0].id.toString());
        }
    }, [shoppingFlow]);

    if (!shoppingFlow || shoppingFlow.length === 0) {
        return null;
    }
    return (
        <S.EnjoyShoppingStyled>
            <Title title={t('widget.shoptheeasyway.title')} />
            <Row>
                <Col xs={24} md={16}>
                    <ul className='list'>
                        {shoppingFlow.map((flowItem: any) => (
                            <li
                                key={flowItem.id}
                                id={flowItem.id.toString()}
                                onMouseEnter={() => setHoveredItem(flowItem.id.toString())}
                                className={hoveredItem === flowItem.id.toString() ? 'active' : ''}
                            >
                                {flowItem.title}
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col xs={24} md={8}>
                    <div className='banner'>
                        {shoppingFlow.map((flowItem: any) => (
                            <img
                                key={flowItem.id}
                                src={flowItem.image.url}
                                alt={flowItem.image.alt}
                                style={{ display: hoveredItem === flowItem.id.toString() ? 'block' : 'none' }}
                            />
                        ))}
                    </div>
                </Col>
            </Row>
        </S.EnjoyShoppingStyled>
    )
}

export default EnjoyShopping;
