import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const CertifiedListMobileCss = css`
    margin-bottom: 0 !important;
    .item {
        .title {
            font-size: ${fonts.size.xxlarge};
        }
        .subTitle {
            font-size: 18px;
            margin-top: 10px;
        }
        &:before {
            top: 8px;
        }
        &:after {
            top: ${fonts.size.medium};
        }
    }
`;

export const CertifiedList = styled.div`
    padding: 20px;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
    .item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 55px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            top: 5px;
            width: 30px;
            height: 30px;
            border-radius: 10px;
            background-color: ${color('general.aquaIsland')};
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            display: inline-block;
            transform: rotate(45deg);
            height: 12px;
            width: 6px;
            border-bottom: 2px solid ${color('general.easternBlue')};
            border-right: 2px solid ${color('general.easternBlue')};
            left: 11px;
            top: 12px;
        }
        .title {
            font-size: ${fonts.size.xxxlarge};
            font-weight: 500;
            font-family: 'Sharp Grotesk';
            color: ${color('base.black')};
        }
        .subTitle {
            font-size: ${fonts.size.xxlarge};
            font-weight: 400;
            margin-top: ${fonts.size.xxlarge};
            font-family: 'Sharp Grotesk';
            color: ${color('base.black')};
        }
    }
    ${mediaQuery.lessThan('xltablet')`${CertifiedListMobileCss}`}
`;
