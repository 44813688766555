import i18n from "i18next";
import qs from "qs";
import {initReactI18next} from "react-i18next";

import commonApiEndpoints from "../../api/common/common.endpoint";
import httpService from "../../api/http/http.service";

export const SupportedLanguage = {
    english: "en",
    arabic: "ar",
};

export type SupportedLanguageType = "en" | "ar";

const defaultLanguage = SupportedLanguage.english;

const userLang = navigator.language;

const launchLanguage = () => {
    const referredLanguage = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })["lang"];

    if (referredLanguage === undefined) {
        const storedLanguage = localStorage?.getItem("@preferredLanguage");

        if (storedLanguage === null) {
            const browsersLanguage = userLang.split("-")[0];
            if (Object.values(SupportedLanguage).includes(browsersLanguage))
                return browsersLanguage;
            return defaultLanguage;
        }
        return storedLanguage;
    }

    return referredLanguage as string;
};

const i18nBackend = {
    type: "backend" as const,
    init: function (
        services: unknown,
        backendOptions: unknown,
        i18nextOptions: unknown
    ) {
        /* initialization */
    },
    read: function (language: unknown, namespace: unknown, callback: any) {
        httpService.client
            .get(commonApiEndpoints.getI18n)
            .then((response: any) => {
                if (response?.result) {
                    callback(
                        null,
                        language === "ar" ? response?.result.ar : response?.result.en
                    );
                }
            })
            .catch((error) => {
                callback(error, false);
            });
    },
};

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        lng: launchLanguage(),
        fallbackLng: "en",
        // resources: {
        //     // en: {},
        //     // ar: {},
        // },
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
        supportedLngs: ["en", "ar"],
    });

// eslint-disable-next-line import/no-anonymous-default-export
export default i18n;
