import * as S from './index.styles';
import React from 'react';
import { Tabs } from 'antd';

import { TabMenuInterface } from 'common/interface/general';

const { TabPane } = Tabs;

const TabMenu: React.FC<TabMenuInterface> = ({ tabContent, activeTab, onTabChange, type }) => {
  return (
    <S.TabMenuStyled>
      <Tabs
        className={`${type === 'overlay' ? 'overlay' : ''}`}
        defaultActiveKey="0"
        //activeKey={activeTab}
        onChange={onTabChange}
      >
        {tabContent.map((tab, index) => (
          <TabPane tab={tab.title} key={index.toString()}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </S.TabMenuStyled>
  );
};

export default TabMenu;
