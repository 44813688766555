
import { Menu } from "antd";
import { Link } from "react-router-dom";
const { SubMenu } = Menu;

const HeaderMenuMobile = ({ menuItems, subMenuItems, menu }: any) => {
  return (
    <>
      <div className="menu">
        <Menu {...menu}>
          {menuItems.map((item: any) => (
            <Menu.Item key={item.key}>
              <Link to={item.link}>{item.title}</Link>
            </Menu.Item>
          ))}
          {
            subMenuItems && (
              subMenuItems.map((subMenu: any) => (
                <SubMenu key={subMenu.key} title={subMenu.title}>
                  {subMenu.items.map((item: any) => (
                    <Menu.Item key={item.key}>{item.title}</Menu.Item>
                  ))}
                </SubMenu>
              ))
            )
          }
          
        </Menu>
      </div>
    </>
  );
};

export default HeaderMenuMobile;
