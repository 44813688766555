import { ButtonProps as AntButtonProps } from 'antd';
import { ButtonStyled } from './index.styles';
import { IconType } from 'components/svg-icon/icon-types';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';

export type ButtonType = 'default' | 'primary' | 'link' | 'dashed' | 'text';

type AllowedAntButtonTypes =
    | 'size'
    | 'disabled'
    | 'children'
    | 'block'
    | 'loading'
    | 'onClick'
    | 'htmlType'
    | 'style'
    | 'className';

export interface ButtonProps
    extends Pick<AntButtonProps, AllowedAntButtonTypes> {
    type?: ButtonType;
    suffixIcon?: IconType | undefined;
    prefixIcon?: IconType | undefined;
    icon?: IconType | undefined;
    htmlType?: 'button' | 'submit' | 'reset'; 
}

const WrappedButton = ({
    type = 'default',
    prefixIcon,
    suffixIcon,
    icon: iconConsume,
    children,
    loading,
    className = '',
    htmlType = 'button',
    ...props
}: ButtonProps) => {
    const extraProps: Pick<AntButtonProps, 'icon' | 'className'> = {};
    if (iconConsume) {
        extraProps.icon = (
            <>
                <SvgIcon name={iconConsume} />
            </>
        );
        extraProps.className = `mispay-btn-with-icon ${className}`;
    }

    // ...

    if (prefixIcon) {
        extraProps.icon = <SvgIcon name={prefixIcon} />;
        extraProps.className = `app-btn-with-icon app-btn-prefix-icon ${className}`;
    }

    // ...

    if (suffixIcon) {
        extraProps.className = `app-btn-with-icon app-btn-suffix-icon ${className}`;
        return (
            <ButtonStyled type={type} htmlType={htmlType} {...props} {...extraProps}>
                {children}
                <SvgIcon name={suffixIcon} fill="#fff" />
            </ButtonStyled>
        );
    }
    extraProps.className = `${className}`;
    return (
        <ButtonStyled
            type={type}
            htmlType={htmlType} 
            {...props}
            {...extraProps}
            loading={loading}
        >
            {children}
        </ButtonStyled>
    );
};

export const Button = React.memo(WrappedButton);
