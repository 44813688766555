import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const EnjoyShoppingMobileCss = css`
    h1 {
        text-align: center;
    }
    .ant-row {
        flex-direction: column-reverse;
        .banner {
            justify-content: center;
        }
        .list {
            li {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                padding-left: 0;
                margin-bottom: 10px;
                &:before {
                    display: none;
                }
            }
        }
        .ant-col {
            &:nth-child(2) {
                margin-top: 40px;
            }
        }
    }
`;

export const EnjoyShoppingStyled = styled.div`
    .list {
        margin-top: 40px;

        li {
            list-style-type: decimal;
            position: relative;
            font-weight: 500;
            font-size: ${fonts.size.xxxlarge};
            padding-left: 10px;
            margin-bottom: 15px;
            cursor: pointer;
            color: ${color('base.black')};

            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 20px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }

            &.active {
                color: ${color('primary.500')};
            }
        }
    }

    .banner {
        display: flex;
        justify-content: flex-end;
        //for test

        img {
            max-height: 500px;
        }
    }

    ${mediaQuery.lessThan('tablet')`${EnjoyShoppingMobileCss}`}
`;
