import {Route, Routes} from "react-router-dom";
import Home from "pages/HomePage";
import HowItWorks from "pages/HowItWorks";
import Business from "pages/Business";
import Shop from "pages/Shop";
import About from "pages/About";
import ShariyaCompliance from "pages/ShariyaCompliance";
import Faqs from "pages/Faqs";
import ContactUs from "pages/ContactUs";
import InStore from "pages/InStore";
import Online from "pages/Online";
import Integration from "pages/Integration";
import Privacy from "pages/Privacy";
import Policy from "pages/Policy";
import {routerPaths} from "./routerPaths";
import BeAPart from "../pages/BeAPart";
import TermsConditions from "../pages/TermsConditions";

const AppRoutes = () => {
    const {
        home,
        shop,
        business,
        howItWorks,
        aboutUs,
        shariyaCompliance,
        faqs,
        contactUs,
        inStore,
        online,
        integration,
        privacy,
        policy,
        beAPart,
        termsConditions
    } = routerPaths;
    return (
        <Routes>
            <Route path={home} element={<Home/>}/>
            <Route path={shop} element={<Shop/>}/>
            <Route path={business} element={<Business/>}/>
            <Route path={howItWorks} element={<HowItWorks/>}/>
            <Route path={aboutUs} element={<About/>}/>
            <Route
                path={shariyaCompliance}
                element={<ShariyaCompliance/>}
            />
            <Route path={faqs} element={<Faqs/>}/>
            <Route path={contactUs} element={<ContactUs/>}/>
            <Route path={inStore} element={<InStore/>}/>
            <Route path={online} element={<Online/>}/>
            <Route path={integration} element={<Integration/>}/>
            <Route path={privacy} element={<Privacy/>}/>
            <Route path={termsConditions} element={<TermsConditions/>}/>
            <Route path={policy} element={<Policy/>}/>
            <Route path={beAPart} element={<BeAPart/>}/>
        </Routes>
    );
};

export default AppRoutes;
