import * as S from './index.styles';

//comp
import useLayoutVisibility from 'common/hooks/useLayoutVisibility';
import Footer from 'widgets/footer';
import Header from 'widgets/header';
import { MainProps } from './types';

const Main = ({ children }: MainProps) => {
    const isLayoutVisible = useLayoutVisibility();

    return (
        <S.MainStyled>
            {isLayoutVisible && <Header></Header>}
            <section>{children}</section>
            {isLayoutVisible && <Footer></Footer>}
        </S.MainStyled>
    );
};

export default Main;
