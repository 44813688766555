import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const ShopSplitManageMobileCss = css`
    text-align: center;
    h1 {
        width: 100%;
        font-size: ${fonts.size.xxxxxlarge};
    }
    h2 {
        font-size: ${fonts.size.large};
        margin-top: 0;
    }
    span {
        font-size: 15px;
        padding: 8px 25px;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
    }
`;

export const ShopSplitManage = styled.div`
    padding: 0px 40px;
    height: 574px;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center;

    h1 {
        font-size: 52px;
        font-weight: 300;
        margin: 40px 0px 0px 0px;
        color: ${color('base.white')};
    }

    h2 {
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        margin: 5px 0px 20px 0px;
        color: ${color('base.white')};
    }

    span {
        font-size: 20px;
        border-radius: 20px;
        padding: 8px 15px;
        margin-bottom: 40px;
        background-color: ${color('base.white')};
        color: ${color('base.black')};
    }

    ${mediaQuery.lessThan('tablet')`${ShopSplitManageMobileCss}`}
`;
