import { useCallback, useEffect, useState } from "react";
import * as S from "./index.styles";
import { useTranslation } from "react-i18next";

// widgets
import Main from "widgets/main";
// comp
import BannerDynamic from "components/banner-dynamic";
import TabMenu from "components/tab-menu";
import usePageApi from "../../api/page/page.api";
import { FaqsResponse, FaqsCustomerResponse, FaqsMerchantsResponse } from "../../api/page/page.model";
import { FaqCard } from "../../widgets/faq-card";

const Faqs = () => {
  const { t } = useTranslation();

  const [pagePayload, setPagePayload] = useState<FaqsResponse>();
  const [pageCustomerPayload, setPageCustomerPayload] = useState<FaqsCustomerResponse>();
  const [pageMerchantsPayload, setPageMerchantsPayload] = useState<FaqsMerchantsResponse>();
  const [tabContent, setTabContent] = useState<any[]>([]); // State for tab content
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getFaqs();

      setPagePayload(response.result);
    } catch (error) {
      console.log("Error fetching home data:", error);
    }
  }, [pageApi]);

  const fetchCustomerData = useCallback(async () => {
    try {
      const response = await pageApi.getFaqsCustomer();

      setPageCustomerPayload(response.result);
    } catch (error) {
      console.log("Error fetching customer data:", error);
    }
  }, [pageApi]);

  const fetchMerchantData = useCallback(async () => {
    try {
      const response = await pageApi.getFaqsMerchants();

      setPageMerchantsPayload(response.result);
    } catch (error) {
      console.log("Error fetching merchant data:", error);
    }
  }, [pageApi]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchHomeData();
        await fetchCustomerData();
        await fetchMerchantData();
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [fetchHomeData, fetchCustomerData, fetchMerchantData]);

  useEffect(() => {
    // Veriler yüklendiyse tabContent'i oluştur
    if (pageCustomerPayload?.widgets?.list && pageMerchantsPayload?.widgets?.list) {
      const updatedTabContent = [
        {
          title: t("faqs.tabmenu.label1"),
          content: (
            <>
              <FaqCard items={pageCustomerPayload?.widgets?.list} />
            </>
          ),
        },
        {
          title: t("faqs.tabmenu.label2"),
          content: (
            <>
              <FaqCard items={pageMerchantsPayload?.widgets?.list} />
            </>
          ),
        },
      ];
      setTabContent(updatedTabContent);
    }
  }, [pageCustomerPayload, pageMerchantsPayload, t]);



  const onSearch = (searchTerm: string) => {
    if (!searchTerm) {
      // Search yapılmadığında tüm verileri göster
      const updatedTabContent = [
        {
          title: t("faqs.tabmenu.label1"),
          content: (
            <>
              <FaqCard items={pageCustomerPayload?.widgets?.list} />
            </>
          ),
        },
        {
          title: t("faqs.tabmenu.label2"),
          content: (
            <>
              <FaqCard items={pageMerchantsPayload?.widgets?.list} />
            </>
          ),
        },
      ];
      setTabContent(updatedTabContent);
    } else {
      // Search yapılıyorsa filtreleme yap
      const filteredCustomerItems = pageCustomerPayload?.widgets?.list?.filter(item =>
        (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.content && item.content.toLowerCase().includes(searchTerm.toLowerCase())) || 
        (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
  
      const filteredMerchantItems = pageMerchantsPayload?.widgets?.list?.filter(item =>
        (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.content && item.content.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
  
      const updatedTabContent = [
        {
          title: t("faqs.tabmenu.label1"),
          content: (
            <>
              <FaqCard items={filteredCustomerItems} />
            </>
          ),
        },
        {
          title: t("faqs.tabmenu.label2"),
          content: (
            <>
              <FaqCard items={filteredMerchantItems} />
            </>
          ),
        },
      ];
  
      setTabContent(updatedTabContent);
    }
    console.log('searchTerm', searchTerm);
  };

  const heroImage = pagePayload?.widgets?.heroImage;
  return (
    <Main>
      <S.Faqs>
        <BannerDynamic
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          mobileImage={heroImage?.mobileImage?.url}
          category={false}
          onSearch={onSearch}
        />
        <section className="children">
          <div className="container">
            <TabMenu tabContent={tabContent} />
          </div>
        </section>
      </S.Faqs>
    </Main>
  );
};
export default Faqs;
