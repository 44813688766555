import { useCallback, useEffect, useState } from "react";
import * as S from "./index.styles";
import { useTranslation } from "react-i18next";

// widgets
import Main from "widgets/main";

// comp
import Banner from "components/banner";
import Title from "components/title";
import usePageApi from "../../api/page/page.api";
import { PolicyResponse } from "../../api/page/page.model";

const Policy = () => {
  const { t } = useTranslation();
  const [pagePayload, setPagePayload] = useState<PolicyResponse>();
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getPolicy();

      setPagePayload(response.result);
    } catch (error) {
      console.log("error", error);
    }
  }, [pageApi]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);
  const heroImage = pagePayload?.widgets.heroImage;
  return (
    <Main>
      <S.Policy>
        <Banner
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          mobileImage={heroImage?.mobileImage?.url}
        />
        <section className="children">
          <div className="container">
            <Title title={t("policy.title")} />
            <p className="info">{t("policy.info")}</p>
          </div>
        </section>
      </S.Policy>
    </Main>
  );
};

export default Policy;
