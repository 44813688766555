import {useRef} from 'react';

import {
    AboutPageResponse,
    CommonWidgetsResponse,
    ContactResponse,
    FaqsResponse,
    FaqsCustomerResponse,
    FaqsMerchantsResponse,
    HomePageResponse,
    HowItWorksPageResponse,
    InStoreResponse,
    IntegrationResponse,
    MerchantsResponse,
    OnlineResponse,
    PageApiCalls,
    PrivacyResponse,
    ShariyaCompliancePageResponse,
    ShopAllResponse,
    ShopDetailResponse,
    ShopCategoryResponse,
    TermsCondtionsResponse
} from './page.model';
import pageApiEndpoints from './page.endpoint';
import httpService from '../http/http.service';
import {HttpResponse} from '../http/http.model';
import {useTranslation} from 'react-i18next';

const usePageApi = (): PageApiCalls => {
    const {i18n} = useTranslation();
    const generateApiCalls = (): PageApiCalls => {
        const headers = {
            params: {
                locale: i18n.language?.replace('/', '')
            }
        };
        return {
            getCommon: (): HttpResponse<CommonWidgetsResponse> =>
                httpService.client.get(pageApiEndpoints.getCommon, headers),
            getHome: (): HttpResponse<HomePageResponse> =>
                httpService.client.get(pageApiEndpoints.getHome, headers),
            getAbout: (): HttpResponse<AboutPageResponse> =>
                httpService.client.get(pageApiEndpoints.getAboutUs, headers),
            getShariyaCompliance: (): HttpResponse<ShariyaCompliancePageResponse> =>
                httpService.client.get(pageApiEndpoints.getShariyaCompliance, headers),
            getHowItWorks: (): HttpResponse<HowItWorksPageResponse> =>
                httpService.client.get(pageApiEndpoints.getHowItWorks, headers),
            getFaqs: (): HttpResponse<FaqsResponse> =>
                httpService.client.get(pageApiEndpoints.getFaqs, headers),
            getFaqsCustomer: (): HttpResponse<FaqsCustomerResponse> =>
                httpService.client.get(pageApiEndpoints.getFaqsCustomer, headers),
            getFaqsMerchants: (): HttpResponse<FaqsMerchantsResponse> =>
                httpService.client.get(pageApiEndpoints.getFaqsMerchant, headers),
            getIntegration: (): HttpResponse<IntegrationResponse> =>
                httpService.client.get(pageApiEndpoints.getIntegration, headers),
            getInStore: (): HttpResponse<InStoreResponse> =>
                httpService.client.get(pageApiEndpoints.getInStore, headers),
            getOnline: (): HttpResponse<OnlineResponse> =>
                httpService.client.get(pageApiEndpoints.getOnline, headers),
            getContact: (): HttpResponse<ContactResponse> =>
                httpService.client.get(pageApiEndpoints.getContact, headers),
            getShopAll: (): HttpResponse<ShopAllResponse> =>
                httpService.client.get(pageApiEndpoints.getShopAll, headers),
            getShopDetail: (): HttpResponse<ShopDetailResponse> =>
                httpService.client.get(pageApiEndpoints.getShopDetail, headers),
            getCategory: (): HttpResponse<ShopCategoryResponse> =>
                httpService.client.get(pageApiEndpoints.getCategoryAll, headers),
            getMerchants: (): HttpResponse<MerchantsResponse> =>
                httpService.client.get(pageApiEndpoints.getMerchants, headers),
            getPrivacy: (): HttpResponse<PrivacyResponse> =>
                httpService.client.get(pageApiEndpoints.getPrivacy, headers),
            getTermsConditions: (): HttpResponse<TermsCondtionsResponse> =>
                httpService.client.get(pageApiEndpoints.getTermsConditions, headers),
            getPolicy: (): HttpResponse<PrivacyResponse> =>
                httpService.client.get(pageApiEndpoints.getPolicy, headers),
            submitContactForm: (data: any): HttpResponse<any> =>
                httpService.client.post(pageApiEndpoints.submitContactForm, data)
        };
    };

    const pageCallsRef = useRef<PageApiCalls>(generateApiCalls());

    return pageCallsRef.current;
};

export default usePageApi;
