import { Link } from "react-router-dom";
import * as S from "./index.styles";
//comp
import nl2br from "react-nl2br";

export interface BannerProps {
  image: any;
  mobileImage?: any;
  title: any;
  subTitle: any;
  qr?: any;
  qrText?: string;
  btnText?: string;
  link?: any;
}

const Banner = ({
  image,
  mobileImage,
  title,
  subTitle,
  qr,
  qrText,
  btnText,
  link,
}: BannerProps) => {
  return (
    <S.BannerStyled>
      <div
        className="container"
        style={{ height: "100%", position: "relative" }}
      >
        <S.Info>
          <S.Title>{nl2br(title)}</S.Title>
          <S.Subtitle>{nl2br(subTitle)}</S.Subtitle>
          {qr && (
            <div className="qr-code-container">
              <S.QRCode>
                <img src={qr} alt="qr" />
              </S.QRCode>
              <span className="qr-text">{qrText}</span>
            </div>
          )}
          {btnText && (
            <Link to={link} className="link">
              {btnText}
            </Link>
          )}
        </S.Info>
        <S.HeroImageContainer>
          <S.HeroImage
            sizes="100%"
            src={image}
            srcSet={mobileImage ? `${image}, ${mobileImage} 768w` : image}
          />
        </S.HeroImageContainer>
      </div>
      <div className="gradient"></div>
    </S.BannerStyled>
  );
};

export default Banner;
