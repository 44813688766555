import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const FaqCardMobileCss = css`
    padding: 20px;
    .head {
        font-size: 20px;
        margin-bottom: 12px;
    }
`;

export const FaqCardStyled = styled.div`
    padding: 40px;
    border-radius: 30px;
    margin-bottom: 20px;
    border: 0;
    box-shadow: 0px 3px 14px ${color('neutral.500')}1A;
    background-color: ${color('base.white')};

    .head {
        font-family: 'Sharp Grotesk';
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 20px;
    }
    ${mediaQuery.lessThan('tablet')`${FaqCardMobileCss}`}
`;
