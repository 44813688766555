import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const LetYourselfBeInspiredMobileCss = css``;

export const LetYourselfBeInspiredStyled = styled.div`
    .our-vision {
        .box {
            border-radius: 30px;
            padding: 35px 25px;
            min-height: 330px;
            background-color: ${color('primary.500')};
            .title {
                font-size: 52px;
                font-weight: 400;
                margin: 0;
                color: white;
            }
            .subTitle {
                margin: 10px 0px 0px 0px;
                font-size: ${fonts.size.xxlarge};
                font-weight: 400;
                color: white;
            }
            .icon {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                .mispay-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 55px;
                    height: 55px;
                    border: 1px solid #78c6ca;
                    font-size: 25px;
                }
            }
        }
    }
    .daily {
        .box {
            border-radius: 30px;
            padding: 20px 20px;
            border: 1px solid ${color('neutral.200')};
            background-color: ${color('neutral.100')};
            min-height: 200px;
            .title {
                font-size: ${fonts.size.xxlarge};
                font-weight: 300;
                line-height: 30px;
                margin: 0;
                color: ${color('base.black')};
            }
            .subTitle {
                font-size: ${fonts.size.xxlarge};
                font-weight: 500;
                color: ${color('primary.500')};
            }
        }
    }
    .banner {
        img {
            max-width: 100%;
        }
    }
    .no-late {
        .box {
            border-radius: 30px;
            padding: 35px 25px;
            min-height: 330px;
            background-color: ${color('primary.100')};
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
                font-size: 60px;
                line-height: 65px;
                font-weight: 600;
                margin: 0;
                color: ${color('primary.500')};
            }
            .subTitle {
                margin: 40px 0px 0px 0px;
                font-size: ${fonts.size.xxlarge};
                font-weight: 400;
                color: ${color('base.black')};
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${LetYourselfBeInspiredMobileCss}`}
`;
