import { useCallback, useEffect, useState } from "react";
import * as S from "./index.styles";
import { useTranslation } from "react-i18next";
import interest from "assets/images/shariya-compliance/interest.svg";

//ui
import { Col, Row } from "antd";
// widgets
import Main from "widgets/main";
// comp
import Banner from "components/banner";
import Title from "components/title";
import CertifiedList from "components/certified-list";
import usePageApi from "../../api/page/page.api";
import { ShariyaCompliancePageResponse } from "../../api/page/page.model";

const ShariyaCompliance = () => {
  const { t } = useTranslation();
  const [pagePayload, setPagePayload] =
    useState<ShariyaCompliancePageResponse>();
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getShariyaCompliance();

      setPagePayload(response.result);
    } catch (error) {
      console.log("error", error);
    }
  }, [pageApi]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);
  const heroImage = pagePayload?.widgets?.heroImage;
  const list = pagePayload?.widgets?.list;
  const about = pagePayload?.widgets?.about;
  return (
    <Main>
      <S.ShariyaCompliance>
        <Banner
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          btnText={heroImage?.buttonLabel}
          mobileImage={heroImage?.mobileImage?.url}
          link={heroImage?.link}
        />
        <section className="children">
          <div className="container">
            <div className="our-products">
              <Title
                title={t("shariyaCompliance.title")}
                subTitle={t("shariyaCompliance.subTitle")}
              />
            </div>
            <div className="certified-list">
              {list?.map((item: any) => (
                <CertifiedList
                  key={item.title}
                  title={item.title}
                  subTitle={item.description}
                />
              ))}
            </div>
            <div className="about-shariyah">
              <Row gutter={[48, 8]}>
                <Col xs={24} lg={8}>
                  <div className="banner">
                    <img src={interest} alt="interest" />
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <div className="info">
                    <h1 className="title">{about?.title}</h1>
                    <p
                      className="paragraph"
                      dangerouslySetInnerHTML={{ __html: about?.content }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </S.ShariyaCompliance>
    </Main>
  );
};

export default ShariyaCompliance;
