import { color } from 'common/utils/pickColor';
import styled from 'styled-components';

export const FeaturedStores = styled.div`
    position: relative;

    img {
        max-width: 100%;
    }

    a {
        display: inline-block;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 18px;
        margin: 0;
        color: ${color('base.white')};
    }
`;
