import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const PaymentSolutionMobileCss = css`
    .title-and-subTitle {
        .title {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 18px;
        }
        .subTitle {
            font-size: 10px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
`;

export const PaymentSolution = styled.div`
    border-radius: 30px;
    .image {
        max-width: 100%;
    }
    .title-and-subTitle {
        .title {
            margin-bottom: 12px;
            margin-top: 5px;
            font-size: ${fonts.size.xxxlarge};
            font-weight: 500;
            color: ${color('base.black')};
        }
        .subTitle {
            font-size: 18px;
            font-weight: 400;
            color: ${color('base.black')};
        }
    }
    ${mediaQuery.lessThan('tablet')`${PaymentSolutionMobileCss}`}
`;
