import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const MobileShopCss = css`
    .over {
        margin-top: 30px;
    }
    .growth-partner,
    .how-it-works {
        h1 {
            &:not(.title) {
                text-align: center;
            }
            font-size: 42px;
        }
    }
    .how-it-works {
        .ant-row {
            margin-top: 20px;
        }
    }
    .seamless {
        text-align: center;
        padding: 20px 15px;
        .ant-row {
            margin-top: 20px;
        }
    }
`;

export const Merchants = styled.div`
    .over {
        margin-top: 70px;
        position: relative;
        .image-list {
            margin: 30px 0px;
            .ant-col {
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    display: block;
                    transition: all 0.5s ease;

                    img {
                        width: 100px;
                    }
                    &:hover {
                        opacity: 0.5;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
        h2 {
            font-family: 'Sharp Grotesk';
        }
    }
    .growth-partner {
        margin-bottom: 50px;
        h1 {
            font-size: ${fonts.size.xxxxxxlarge};
            font-weight: 600;
        }
        .ant-row {
            margin-top: 40px;
        }
    }
    .how-it-works {
        .ant-row {
            margin-top: 40px;
        }
    }
    .seamless {
        margin-top: 40px;
        padding: 50px 0px;
        background-color: ${color('general.whisper')};
        .ant-row {
            margin-top: 40px;
        }
    }
    ${mediaQuery.lessThan('tablet')`${MobileShopCss}`}
`;
