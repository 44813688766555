const pageApiEndpoints = {
    getCommon: "/common/detail",
    getHome: "/home/detail",
    getAboutUs: "/about-us/detail",
    getShariyaCompliance: "/shariya-compliance/detail",
    getHowItWorks: "/how-it-works/detail",
    getFaqs: "/faq/detail",
    getFaqsCustomer: "/faq-customer/detail",
    getFaqsMerchant: "/faq-merchant/detail",
    getIntegration: "/integration/detail",
    getInStore: "in-store/detail",
    getOnline: "online/detail",
    getContact: "contact/detail",
    getShopAll: "shop/all",
    getShopDetail: "shop-page/detail",
    getCategoryAll: "category/all",
    getMerchants: "merchants/detail",
    getPrivacy: "privacy/detail",
    getTermsConditions: "/terms-condition/detail",
    getPolicy: "policy/detail",
    submitContactForm: "/contact-submit"
};

export default pageApiEndpoints;
