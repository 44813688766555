import { useCallback, useEffect, useState } from 'react';
import * as S from './index.styles';

// widgets
import Main from 'widgets/main';

// comp
import Banner from 'components/banner';
import Title from 'components/title';
import usePageApi from '../../api/page/page.api';
import { TermsCondtionsResponse } from '../../api/page/page.model';
import useLayoutVisibility from 'common/hooks/useLayoutVisibility';

const TermsConditions = () => {
    const [pagePayload, setPagePayload] = useState<TermsCondtionsResponse>();
    const pageApi = usePageApi();
    const isLayoutVisible = useLayoutVisibility();

    const fetchHomeData = useCallback(async () => {
        try {
            const response = await pageApi.getTermsConditions();

            setPagePayload(response.result);
        } catch (error) {
            console.log('error', error);
        }
    }, [pageApi]);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData]);
    const heroImage = pagePayload?.widgets.heroImage;
    return (
        <Main>
            <S.Privacy>
                {isLayoutVisible && (
                    <Banner
                        title={heroImage?.title}
                        subTitle={heroImage?.desc}
                        image={heroImage?.image.url}
                        mobileImage={heroImage?.mobileImage?.url}
                    />
                )}
                <section className="children">
                    <div className="container">
                        <br />
                        <br />
                        <Title title={pagePayload?.widgets.richText.title} />

                        <div
                            className="info"
                            dangerouslySetInnerHTML={{
                                __html: pagePayload?.widgets?.richText?.content || ''
                            }}
                        />
                    </div>
                </section>
            </S.Privacy>
        </Main>
    );
};

export default TermsConditions;
