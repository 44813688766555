import AppRoutes from "config/routes";

import {withRTLSupport} from "common/rtl";
import {GlobalStyles} from "common/theme/global";
import {CommonProvider} from "./context/common/common.context";
import {HelmetProvider} from "react-helmet-async";

const App = () => {
    return (
        <HelmetProvider>
            <CommonProvider>
                <GlobalStyles/>
                <AppRoutes/>
            </CommonProvider>
        </HelmetProvider>
    );
};

export default withRTLSupport(App);
