import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const OnlineMobileCss = css`
    .giants {
        p {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    .all-about-the-numbers {
        h1 {
            text-align: center;
        }
    }
    .how-it-works {
        h1 {
            &:not(.title) {
                text-align: center;
            }
        }
        .ant-row {
            margin-top: 30px;
        }
    }
    .integration {
        text-align: center;
        margin: 40px 0px;
        h1 {
            font-size: 22px;
            margin-bottom: 12px;
        }
        h2 {
            font-size: ${fonts.size.large};
        }
    }
`;

export const OnlineStyled = styled.div`
    .giants {
        text-align: center;
        margin-top: 60px;
        p {
            font-size: ${fonts.size.xxlarge};
            font-weight: 500;
            margin-bottom: 10px;
            color: ${color('base.black')};
        }
        .ant-row {
            align-items: center;
            .ant-col {
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .all-about-the-numbers {
        margin: 60px 0px;
        .ant-row {
            margin: 30px 0px;
        }
    }
    .how-it-works {
        .ant-row {
            margin-top: 50px;
        }
    }
    .integration {
        margin: 80px 0px;
        h2 {
            margin-top: 12px;
            font-size: ${fonts.size.xxlarge} !important;
            font-weight: 500;
        }
        .ant-row {
            margin-top: 30px;
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                border-radius: 20px;
                min-height: 95px;
                background-color: #fcfcfd;
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${OnlineMobileCss}`}
`;
