import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const OperatingMobileCss = css`
    .icon {
        max-width: 60px;
        margin: 0 auto;
    }
    .title {
        text-align: center;
    }
`;

export const Operating = styled.div`
    padding: 20px;
    border-radius: 20px;
    background-color: ${color('neutral.100')};
    .icon {
        background-color: ${color('primary.100')};
        border-radius: 10px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .mispay-icon {
            display: flex;
            align-items: center;
            font-size: ${fonts.size.xxxxlarge};
        }
    }
    .title {
        font-size: ${fonts.size.xxxlarge};
        font-weight: 500;
        margin-top: 5px;
        margin: 15px 0px;
        color: ${color('base.black')};
    }
    .subTitle {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0;
        color: ${color('base.black')};
    }
    ${mediaQuery.lessThan('tablet')`${OperatingMobileCss}`}
`;
