import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: `/api/`,
  headers: {
    Authorization:
      "Bearer 1ff80465c8a2c23cba5a2a2294b5d002a5acd672be32061892d5f28160695845ec3bd4e4a5ea4d0ba779aca36d43f14af127d88152d85774be45222c2dc9359abadd85a28bcf90cb9a68209cc629f8cf585db6994b8048e55319a10444163b070e18fa8b7be1ebd1bbc2b31c7a09b9a1280b95ddcfa795ca91e9a388ffc493ae",
  },
};

export class HttpService {
  private _client: AxiosInstance;

  public errorHandler?: (response: AxiosResponse) => void;

  constructor() {
    this._client = axios.create(axiosConfig);
    this.applyInterceptor();
  }

  applyInterceptor() {
    this._client.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        if (this.errorHandler) {
          this.errorHandler(error.response);
        }
        return Promise.reject(error);
      }
    );
  }

  public get client(): AxiosInstance {
    return this._client;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HttpService();
