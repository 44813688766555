import * as S from "./index.styles";
import { FeaturedProducts } from "../../api/page/page.model";
import { useTranslation } from "react-i18next";
import { SupportedLanguageType } from "../../assets/i18n";

const FeaturedStores = ({ item }: { item: FeaturedProducts }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  return (
    <S.FeaturedProducts>
      <a href={item.link} target="__blank">
        <img src={item.image.url} alt="featured-store" />
      </a>

      <div className="detail">
        {item?.category ? (
          <div className="left-side">
            <img src={item.category?.shop?.image?.url} alt="" />
            <div className="name-and-subname">
              <h6>
                {item?.category[
                  i18n?.language as unknown as SupportedLanguageType
                ] ?? ""}
              </h6>
              <h5>
                {item?.category[
                  i18n?.language as unknown as SupportedLanguageType
                ] ?? ""}
              </h5>
            </div>
          </div>
        ) : null}
        <div className="right-side">
          <span className="status">{t("widget.featuredProductsUpTo")}</span>
          <span className="discount">
            {item.discount}% {t("widget.featuredProductsDiscount")}
          </span>
        </div>
      </div>
    </S.FeaturedProducts>
  );
};

export default FeaturedStores;
