import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const MapsAndInfoMobileCss = css`
    .iframe {
        iframe {
            width: 100%;
        }
    }
    .info {
        .address {
            .text {
                font-size: 18px;
            }
        }
        .phone-and-email {
            flex-direction: column;
            .phone {
                margin: 10px 0px 20px 0px;
                .text {
                    font-size: 18px;
                }
            }
            .email {
                .text {
                    font-size: 18px;
                }
            }
        }
    }
`;

export const MapsAndInfoStyled = styled.div`
    .iframe {
        iframe {
            border-radius: 30px;
            height: 612px;
            border: none;
        }
    }
    .info {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${color('base.black')};
        margin-top: 15px;
        border-radius: 30px;
        padding: 30px 35px;
        .text {
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            color: ${color('base.black')};
        }
        .address {
            display: flex;
            align-items: center;
            svg {
                position: relative;
                left: -15px;
                font-size: 60px;
            }
        }
        .phone-and-email {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
            svg {
                margin-right: 20px;
                font-size: ${fonts.size.xxxxlarge};
            }
            .phone,
            .email {
                display: flex;
                align-items: center;
                .mispay-icon {
                    max-height: 38px;
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${MapsAndInfoMobileCss}`}
`;
