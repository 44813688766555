import { useCallback, useEffect, useState } from "react";
import * as S from "./index.styles";

//ui
import { Row, Col } from "antd";
// widgets
import Main from "widgets/main";
import ContactUsForm from 'widgets/contact-us/form';
import MapsAndInfo from 'widgets/contact-us/maps-and-info';

// comp
import Banner from "components/banner";
// common
import {useCommon} from "context/common/common.context";

import usePageApi from "../../api/page/page.api";
import { ContactResponse } from "../../api/page/page.model";

const ContanctUs = () => {
  const common = useCommon();
  const qR = common.source?.widgets?.qrCode?.image?.url;

  const [pagePayload, setPagePayload] = useState<ContactResponse>();
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getContact();
      setPagePayload(response.result);
    } catch (error) {
      console.log("error", error);
    }
  }, [pageApi]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);
  const heroImage = pagePayload?.widgets?.heroImage;
  return (
    <Main>
      <S.ContanctUs>
        <Banner
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          mobileImage={heroImage?.mobileImage?.url}
          qr={qR}
        />
        <section className="children">
        <div className="container">
          <Row gutter={[16,16]} className="form-and-info">
            <Col xs={24} lg={12}>
              <ContactUsForm />
            </Col>
            <Col xs={24} lg={12}>
              <MapsAndInfo />
            </Col>
          </Row>
        </div>
        </section>
      </S.ContanctUs>
    </Main>
  );
};

export default ContanctUs;
