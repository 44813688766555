import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const InStoreMobileCss = css`
    h1 {
        &:not(.title) {
            text-align: center;
        }
    }
    .how-it-works {
        .ant-row {
            margin-top: 30px;
        }
    }
`;

export const InStore = styled.div`
    .giants {
        text-align: center;
        margin-top: 50px;
        p {
            font-size: ${fonts.size.xxlarge};
            font-weight: 500;
            margin-bottom: 10px;
            color: ${color('base.black')};
        }
        .ant-row {
            align-items: center;
            .ant-col {
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .all-about-the-numbers {
        margin: 50px 0px;
        .ant-row {
            margin: 30px 0px;
        }
    }
    .how-it-works {
        margin-top: 30px;
        .ant-row {
            margin-top: 40px;
        }
    }
    .dynamic-card {
        margin-top: 60px;
    }
    ${mediaQuery.lessThan('xltablet')`${InStoreMobileCss}`}
`;
