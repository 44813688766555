import * as S from "./index.styles";

//ui
import { Row, Col } from "antd";

//common
import { DynamicCardInterface } from "common/interface/general";

//comp
import { Button } from "components/button";
import { SvgIcon } from "components/svg-icon";

const DynamicCard = ({
  image,
  title,
  subTitle,
  extra,
  btnText,
  btnOnClick,
  extraList,
  rtl,
}: DynamicCardInterface) => {
  return (
    <S.DynamicCardStyled>
      <Row gutter={[48,48]} className={rtl ? 'rtl' : 'ltr'}>
        <Col xs={24} md={12}>
          <div className="image">
            <img src={image} alt="card" />
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="card-info">
            <h1 className="title">{title}</h1>
            <h2 className="subTitle" dangerouslySetInnerHTML={{__html: subTitle as string}}></h2>
            {extra && (
              <div className="extra">
                <div className="item-list">
                  {extraList?.map((item: any) => (
                    <div className="item" key={item.icon}>
                      <SvgIcon name={item.icon} />
                      <div className="text">
                        <h3 className="extra-tit">{item.title}</h3>
                        <h4 className="extra-sub" dangerouslySetInnerHTML={{__html: item.content}} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Button type="primary" onClick={btnOnClick}>
              {btnText}
            </Button>
          </div>
        </Col>
      </Row>
    </S.DynamicCardStyled>
  );
};

export default DynamicCard;
