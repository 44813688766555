import * as S from "./index.styles";
import nl2br from "react-nl2br";
import {PaymentSolutionInterface} from "common/interface/general";

const PaymentSolution = ({image, title, subTitle}: PaymentSolutionInterface) => {
    return (
        <S.PaymentSolution>
            <img src={image} alt="payment-solution" className="image"/>
            <div className="title-and-subTitle">
                <h1 className="title">{nl2br(title)}</h1>
                <h2 className="subTitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
            </div>
        </S.PaymentSolution>
    );
};

export default PaymentSolution;
