import * as S from "./index.styles";
import nl2br from "react-nl2br";
import { CertifiedListInterface } from "common/interface/general";

const CertifiedList = ({ title, subTitle }: CertifiedListInterface) => {
  return (
    <S.CertifiedList>
      <div className="item">
          <span className="title">{nl2br(title)}</span>
          <span className="subTitle">{nl2br(subTitle)}</span>
          </div>
    </S.CertifiedList>
  );
};

export default CertifiedList;
