import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';;


const MobileShopCss = css`
  h1 {
    text-align:center;
  }
  .box {
    &.beauty-health, &.fitness-outdoor {
        margin-top:30px;
    }
  }
`

export const Shop = styled.div`
    .box {
        margin-top: 30px;
    }
    .box {
        .ant-row {
            margin: 30px 0px 30px 0px;
        }
        .button-container {
            text-align:center;
        }
    }
    ${mediaQuery.lessThan('tablet')`${MobileShopCss}`}
`