import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const SeamlessMobileCss = css`
    .title {
        font-size: 17px;
    }
`;

export const Seamless = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid ${color('general.mischka2')};
    background-color: ${color('general.catskillWhite3')};
    .image {
        width: 93px;
        height: 93px;
        padding: 5px 5px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${color('base.white')};
    }
    .title {
        font-size: 17px;
        margin-top: 12px;
        font-weight: 400;
        color: ${color('base.black')};
    }
    ${mediaQuery.lessThan('tablet')`${SeamlessMobileCss}`}
`;
