import { FaqCardStyled } from "./index.styles";
import AccordionMenu from "../../components/accordion-menu";
import { FaqItem } from "../../api/page/page.model";

interface FaqCardProps {
  items: any;
}

export const FaqCard = ({ items }: FaqCardProps) => {
  const groupFaqItems = (items: FaqItem[]) => {
    const groupedFaqs: { [key: string]: FaqItem[] } = {};

    let currentGroup: string = '';

    items?.forEach((item) => {
      if (item.name) {
        currentGroup = item.name;
        groupedFaqs[currentGroup] = []; 
      } else {
        if (!groupedFaqs[currentGroup]) {
          groupedFaqs[currentGroup] = [];
        }
        groupedFaqs[currentGroup].push(item);
      }
    });

    return groupedFaqs;
  };

  const groupedFaqs = groupFaqItems(items);

  return (
    <>
    {Object.entries(groupedFaqs).map(([head, items], index) => (
        <FaqCardStyled key={index}>
          <div className="head">{head}</div>
          <AccordionMenu list={items.map(item => ({ title: item.title, content: item.content }))} />
        </FaqCardStyled>
      ))}
    </>
  );
};
