import * as S from "./index.styles";
import { OperatingInterface } from "common/interface/general";

import { SvgIcon } from "components/svg-icon";

const Operating = ({ icon, title, subTitle }: OperatingInterface) => {
  return (
    <S.Operating>
      <div className="icon">
        <SvgIcon name={icon} />
      </div>
      <div className="title">{title}</div>
      <p className="subTitle" dangerouslySetInnerHTML={{__html: subTitle}} />
    </S.Operating>
  );
};

export default Operating;
