import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';
import BannerBg from 'assets/images/banner-bg.png';

const DesktopBannerCss = css`
    height: 500px;
`;
const TabletBannerCss = css`
    height: 400px;
`;
const MobileBannerCss = css`
    height: 268px;
    padding: 0px 20px;
    .ant-btn {
        span {
            line-height: 25px;
        }
    }
`;

export const BannerDynamicStyled = styled.article`
    height: 770px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: ${color('primary.500')};
    background-image: url(${BannerBg});

    .gradient {
        width: 100%;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
        z-index: 1;
    }
    ${mediaQuery.lessThan('desktop')`${DesktopBannerCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerCss}`}
`;

export const Info = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
`;

const DesktopBannerTitleCss = css`
    font-size: ${fonts.size.xxxxlarge};
    line-height: 52px;
`;

const TabletBannerTitleCss = css`
    font-size: 30px;
    line-height: 42px;
`;

const MobileBannerTitleCss = css`
    font-size: 18px;
    line-height: 25px;
    margin-top: 70px;
`;

export const Title = styled.h1`
    margin: 0;
    margin-top: 30px;
    font-size: ${fonts.size.xxxxxxlarge};
    line-height: 70px;
    font-weight: 600;
    color: ${color('base.white')};
    ${mediaQuery.lessThan('desktop')`${DesktopBannerTitleCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerTitleCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerTitleCss}`}
`;

const DesktopBannerSubTitleCss = css`
    font-size: 20px;
`;
const TabletBannerSubTitleCss = css`
    font-size: ${fonts.size.large};
`;
const MobileBannerSubTitleCss = css`
    font-size: 10px;
    margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
    max-width: 1050px;
    font-size: ${fonts.size.xxxlarge};
    font-weight: 500;
    color: ${color('base.white')};
    ${mediaQuery.lessThan('desktop')`${DesktopBannerSubTitleCss}`}
    ${mediaQuery.lessThan('xltablet')`${TabletBannerSubTitleCss}`}
  ${mediaQuery.lessThan('tablet')`${MobileBannerSubTitleCss}`}
`;

const MobileQrCodeCss = css`
    max-width: 50px;
    max-height: 50px;
    padding: 6px;
    border-radius: 12px;
    img {
        border-radius: 8px;
        max-width: 50px;
        max-height: 50px;
    }
`;

export const QRCode = styled.div`
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 20px;
    display: block;
    border-radius: 20px;
    padding: 12px;
    border: 1px solid ${color('base.white')};
    img {
        max-width: 80px;
        border-radius: 12px;
        display: block;
    }

    ${mediaQuery.lessThan('tablet')`${MobileQrCodeCss}`}
`;

const MobileBannerSearchCss = css`
    width: 100%;
    .ant-input-search {
        .ant-input-wrapper {
            .ant-input-affix-wrapper {
                height: 60px;
                .ant-input {
                    font-size: 13px;
                }
                .ant-input-suffix {
                    .ant-dropdown-trigger {
                        font-size: ${fonts.size.large};
                    }
                }
            }
        }
    }
`;

export const Search = styled.div`
    width: 700px;
    &.faq-search {
        width: 90%;
    }
    .ant-input-search {
        .ant-input-wrapper {
            .ant-input-affix-wrapper {
                height: 70px;
                border-radius: 12px !important;
                padding-left: 20px;
                &:hover {
                    border: none;
                }
                .ant-input-prefix {
                    margin-right: 20px;
                    .ant-input-clear-icon {
                    }
                }
                .ant-input-suffix {
                    cursor: pointer;
                    border-left: 1px solid ${color('neutral.100')};
                    padding-left: 15px;
                    .ant-input-clear-icon.ant-input-clear-icon-has-suffix {
                        display: none;
                    }
                    .ant-dropdown-trigger {
                        font-size: 18px;
                        font-weight: 600;
                        color: ${color('general.ebony')};
                    }
                    .mispay-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin-left: 12px;
                        border-radius: 50%;
                        width: ${fonts.size.xxlarge};
                        height: ${fonts.size.xxlarge};

                        &.arrow-icon {
                            transform: rotate(90deg);
                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                height: 20px;
                                width: 20px;
                                background-color: ${color('primary.500')};
                                top: 50%;
                                left: 50%;
                                transform: translate(calc(-50% - 1px), -50%);
                                z-index: -1;
                                border-radius: 50%;
                            }
                        }
                        svg {
                            font-size: 12px;
                        }
                    }
                }
                .ant-input {
                    color: ${color('general.ebony')};
                    font-size: 18px;
                    &::placeholder {
                        color: ${color('general.ebony')};
                    }
                }
            }
        }
        .ant-input-group-addon {
            display: none;
            .ant-btn {
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${MobileBannerSearchCss}`}
`;

export const Send = styled.div`
    display: flex;
    align-items: center;
    span {
        font-size: 18px;
        font-weight: 600;
    }
    .mispay-icon {
        height: 24px;
        width: 24px;
        transform: rotate(0deg);
        margin-left: 4px !important;
    }
`;
