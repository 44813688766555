import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';


const FaqsMobileCss = css`
    .ant-tabs {
        .ant-tabs-nav {
            margin-bottom:30px;
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        .ant-tabs-tab-btn {
                            font-size:22px;
                        }
                    }
                }
            }
        }

    }
`
export const Faqs = styled.div`
    .ant-tabs {
        margin-top: 30px;

        .ant-tabs-tab {
            .ant-tabs-tab-btn {
                padding: 0 20px;
                margin: 0 20px;
            }
        }

        .ant-tabs-nav {
            margin-bottom: 50px;

            &:before {
                display: none;
            }

            .ant-tabs-nav-wrap {
                justify-content: center;
            }
        }
    }

    ${mediaQuery.lessThan('tablet')`${FaqsMobileCss}`}
`