import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const ShariyaComplianceMobileCss = css`
    .our-products {
        text-align: center;
    }
    .certified-list {
        padding: 0px 0px;
    }
    .about-shariyah {
        .banner {
            text-align: center;
            img {
                max-height: 300px;
            }
        }
        .info {
            text-align: center;
            margin-top: 20px;
            .title {
                text-align: center;
                font-size: ${fonts.size.xxlarge};
                line-height: 30px;
            }
            .paragraph {
                font-size: 18px;
            }
        }
    }
`;

export const ShariyaCompliance = styled.div`
    .our-products {
        margin-top: 40px;
    }
    .certified-list {
        margin-top: 40px;
        border-radius: 20px;
        padding: 15px 15px;
        border: 1px solid ${color('base.black')};
    }
    .about-shariyah {
        margin-top: 60px;
        .banner {
            img {
                max-width: 100%;
            }
        }
        .info {
            .title {
                margin-top: 0;
                margin-bottom: 0;
                font-size: ${fonts.size.xxxxxlarge};
                font-weight: 600;
                color: ${color('base.black')};
            }
            .paragraph {
                margin-bottom: 0;
                font-size: ${fonts.size.xxlarge};
                line-height: 35px;
                font-weight: 400;
                color: ${color('base.black')};
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${ShariyaComplianceMobileCss}`}
`;
