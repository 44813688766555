import styled from 'styled-components';

export const HowItWorks = styled.div`

    .shop-split-manage-row {
        margin: 50px 0px;
        .ant-col {
        }
    }
    .enjoy-shopping {
        margin-bottom:50px;
    }
`