import {useTranslation} from 'react-i18next';
import * as S from './index.styles';
import banner from 'assets/images/about-us/shop.svg';
//ui
import {Col, Row} from 'antd';
//comp

const LetYourselfBeInspired = ({greenCard, blackCard}: any) => {
    const {t} = useTranslation();
    const greenCardTitle = greenCard ? greenCard.title : '';
    const greenCardContent = greenCard ? greenCard.content : '';
    const blackCardTitle = blackCard ? blackCard.title : '';
    const blackCardContent = blackCard ? blackCard.content : '';
    return (
        <S.LetYourselfBeInspiredStyled>
            <Row gutter={[12, 12]} style={{alignItems: 'end'}}>
                <Col xs={24} md={12} lg={7} className="our-vision">
                    <div className="box">
                        <h1 className="title">{greenCardTitle}</h1>
                        <h2
                            className="subTitle"
                            dangerouslySetInnerHTML={{__html: greenCardContent}}
                        ></h2>
                    </div>
                </Col>
                <Col xs={24} md={12} lg={4} className="daily">
                    <div className="box">
                        <h1 className="title">{t('about.letYourselfCard2Title')}</h1>
                        <h2 className="subTitle">{t('about.letYourselfCard2SubTitle')}</h2>
                    </div>
                </Col>
                <Col md={12} lg={6} className="banner">
                    <img src={banner} alt="banner" />
                </Col>
                <Col xs={24} md={12} lg={7} className="no-late">
                    <div className="box">
                        <h1 className="title">{blackCardTitle}</h1>
                        <h2
                            className="subTitle"
                            dangerouslySetInnerHTML={{__html: blackCardContent}}
                        ></h2>
                    </div>
                </Col>
            </Row>
        </S.LetYourselfBeInspiredStyled>
    );
};

export default LetYourselfBeInspired;
