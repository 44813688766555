import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

const WannaOfferYourCustomersTabletCss = css`
    .get-your-personal {
        padding: 32px 45px;
        min-height: 275px;
        h1 {
            font-size: 45px;
            line-height: ${fonts.size.xxxxxlarge};
            max-width: 430px;
        }
    }
    .split-and-qr {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .pc {
            display: none;
        }
    }
    .only-and-pc {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        .only {
            padding: 24px 24px;
            max-width: 350px;
            margin: 0 auto;
        }
    }
`;

const WannaOfferYourCustomersMobileCss = css`
    margin-top: 20px;
    .get-your-personal {
        border-radius: 12px;
        min-height: 185px;
        img {
            bottom: 0px;
            max-width: 80px;
        }
        h1 {
            font-size: 30px;
            line-height: 37px;
        }
    }
    .split-and-qr {
        .split {
            border-radius: 12px;
            min-height: 137px;
            h1 {
                font-size: 32px;
                line-height: 37px;
                padding: 25px 0px 0px 20px;
                strong {
                    font-size: 32px;
                }
            }
            img {
                max-width: 100px;
            }
        }
    }
    .only-and-pc {
        margin-top: 20px;
        .only {
            padding: 24px 24px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
`;

export const WannaOfferYourCustomers = styled.div`
    margin-top: 40px;

    .get-your-personal {
        border-radius: 30px;
        overflow: hidden;
        padding: 32px !important;
        position: relative;
        background-color: ${color('primary.100')};

        h1 {
            font-size: ${fonts.size.xxxxxxlarge};
            font-weight: 600;
            line-height: 1.2;
            max-width: 350px;
            margin: 0;
            color: ${color('primary.500')};
        }
        img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .split-and-qr {
        .split {
            border-radius: 30px;
            margin-bottom: 15px;
            position: relative;
            background-color: ${color('primary.500')};
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: ${color('base.white')};
                max-width: 350px;
                line-height: 1.4;
                margin: 0 0;
                padding: 20px 30px 50px 30px;
                strong {
                    font-size: 52px;
                    font-weight: bold;
                }
            }
            img {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .only {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 30px;
            background-color: ${color('neutral.100')};
            img {
                width: 145px;
                height: 145px;
                border-radius: 24px;
                border: 1px solid ${color('neutral.200')};
            }
            span {
                font-size: 24px;
                font-weight: 400;
                margin-top: 12px;
                color: ${color('base.black')};
            }
        }
        .pc {
            img {
                max-width: 100%;
            }
        }
    }
    .only-and-pc {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    ${mediaQuery.lessThan('xltablet')`${WannaOfferYourCustomersTabletCss}`}
    ${mediaQuery.lessThan('tablet')`${WannaOfferYourCustomersMobileCss}`}
`;
