import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const BasicCardMobileCss = css`
    padding: 30px 20px;
    min-height: auto;
    .info {
        .title {
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 10px;
        }
        .subTitle {
            font-size: ${fonts.size.large};
        }
    }
`;

export const BasicCardStyled = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px 20px 0px 20px;
    min-height: 275px;
    background-color: ${color('neutral.100')};

    .icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 10px;
        background-color: ${color('primary.100')};

        .mispay-icon {
            height: 40px;
            svg {
                font-size: 40px;
            }
        }
    }
    .info {
        margin-top: 15px;
        .title {
            margin: 0;
            font-size: ${fonts.size.xxxlarge};
            font-weight: 500;
            color: ${color('base.black')};
        }
        .subTitle {
            margin: 0;
            font-size: 18px;
            font-weight: 400;
            margin-top: 20px;
            color: ${color('base.black')};
        }
        .ant-btn {
            &.ant-btn-link {
                padding-left: 0;
                font-size: 18px;
                font-weight: 600;
                padding-right: 0;
                color: ${color('primary.500')} !important;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${BasicCardMobileCss}`}
`;
