import styled, { css } from 'styled-components';
import { Row } from 'antd';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';

const mobileCss = css`
    display: none !important;
`;

const DesktopCss = css`
    .ant-menu-item {
        margin: 0px 5px !important;
    }
`;

export const HeaderContainer = styled.header`
    &.sticky {
        position: fixed;
        top: 0;
        padding: 12px 0px;
        background-color: white;
        box-shadow: 0px -3px 15px #d5d5d5;
        .ant-col-5 {
            a {
                display: block;
                img {
                    max-width: 150px;
                }
            }
        }
        .ant-menu {
            .ant-menu-item {
                &.ant-menu-item-selected {
                    border-color: black;
                }
                span {
                    a {
                        color: black;
                    }
                }
            }
        }
        .ant-btn-link {
            span {
                color: ${color('base.black')};
            }
        }
        .ant-btn-text {
            background-color: #4d9fa5;
            span {
                color: white;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    position: absolute;
    z-index: 99;
    top: 20px;
    right: 0;
    left: 0;
    ${mediaQuery.lessThan('desktop')`${DesktopCss}`}
    ${mediaQuery.lessThan('xltablet')`${mobileCss}`}
`;

export const RowStyled = styled(Row)``;

export const LogoMenuAuthContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const LogoContainer = styled.div`
    flex: 1;
`;

export const MenuAuthContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const MenuContainer = styled.div`
    margin-right: 35px;
`;

export const AuthenticationContainer = styled.div`
    display: flex;
    column-gap: 12px;

    .ant-btn-text {
        margin: 0 10px;
    }
    .ant-btn-link {
        padding: 0;
    }
`;
