import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';


const mobileCss = css`
    img {
        margin: 0 auto;
        max-width:250px;
    }
    .title {
        font-size:${fonts.size.large};
    }
    .info {
        font-size:10px;
    }
`;

export const ShopTheEasyWayStyled = styled.div`
    text-align:center;
    .image {
        display: block;
        width:100%;
        height:300px;
        background-size:cover;
        border-radius:30px;
    }
    .title {
        font-size:22px;
        margin-bottom:12px;
        font-weight:600;
    }
    .info {
        font-size:15px;
        margin:0 auto;
        font-weight:300;
        max-width:300px;
    }
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`