import * as S from "./index.styles";
import {FeaturedStoresInterface} from "common/interface/home";

const FeaturedStores = ({image, title, link}: FeaturedStoresInterface) => {
    return (
        <S.FeaturedStores>
            <a href={link} target="_blank" rel="noreferrer" title={title}>
                <img src={image} alt={title}/>
            </a>
        </S.FeaturedStores>
    );
};

export default FeaturedStores;
