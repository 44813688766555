/* eslint-disable jsx-a11y/iframe-has-title */
import * as S from "./index.styles";
import {useTranslation} from "react-i18next";
//comp
import {SvgIcon} from "components/svg-icon";

const MapsAndInfo = () => {
  const { t } = useTranslation();
  return (
    <S.MapsAndInfoStyled>
      <div className="iframe">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3622.4381572745992!2d46.6409174!3d24.7804458!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee305c36d3777%3A0x76d4ae6a445efa11!2smispay!5e0!3m2!1str!2str!4v1712220174794!5m2!1str!2str"
          width="600"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="info">
        <div className="address">
          <SvgIcon name="location" />
          <span className="text">{t("contactus.info.text1")}</span>
        </div>
        <div className="phone-and-email">
          <div className="phone">
            <SvgIcon name="phone" />
            <span className="text">{t("contactus.info.text2")}</span>
          </div>
          <div className="email">
            <SvgIcon name="mail" />
            <span className="text">{t("contactus.info.text3")}</span>
          </div>
        </div>
      </div>
    </S.MapsAndInfoStyled>
  );
};

export default MapsAndInfo;
