import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { Row } from 'antd';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

const FooterStyledMobileCss = css`
    margin-top: 40px;
    &:before {
        display: none !important;
    }
    .social-media-and-menu {
        margin-top: 30px;
        .ant-col:first-child {
            text-align: center;
            margin-bottom: 50px;
        }
    }
`;
export const FooterStyled = styled.footer`
    margin-top: 130px;
    position: relative;
    background-color: ${color('primary.250')};

    ${mediaQuery.lessThan('tablet')`${FooterStyledMobileCss}`}
`;

const RowStyledBecomeDesktopCss = css`
    img {
        max-width: 500px;
        top: -79px;
    }
`;

const RowStyledBecomeTabletCss = css`
    img {
        max-width: 450px;
        top: -43px;
    }
`;

const RowStyledBecomeMobileCss = css`
    .become {
        text-align: center;
        h3 {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    .banner {
        display: none;
    }
`;

export const RowStyledBecome = styled(Row)`
    .ant-btn-default {
        background-color: transparent;
        border-color: ${color('primary.500')};
    }
    img {
        position: absolute;
        right: 0;
        max-width: 570px;
        top: -132px;
    }
    ${mediaQuery.lessThan('desktop')`${RowStyledBecomeDesktopCss}`}
    ${mediaQuery.lessThan('xltablet')`${RowStyledBecomeTabletCss}`}
  ${mediaQuery.lessThan('tablet')`${RowStyledBecomeMobileCss}`}
`;

const TitleTabletCss = css`
    font-size: ${fonts.size.xxxlarge};
`;

const TitleMobileCss = css`
    font-size: 28px;
    line-height: 35px;
`;

export const Title = styled.h2`
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 45px;
    color: ${color('primary.500')};
    ${mediaQuery.lessThan('xltablet')`${TitleTabletCss}`}
    ${mediaQuery.lessThan('tablet')`${TitleMobileCss}`}
`;

const SubTitleTabletCss = css`
    font-size: 19px;
`;

const SubTitleMobileCss = css`
    font-size: ${fonts.size.large};
    font-weight: 400;
`;

export const SubTitle = styled.h3`
    font-size: 23px;
    margin-top: 0;
    color: ${color('base.white')};
    ${mediaQuery.lessThan('xltablet')`${SubTitleTabletCss}`}
    ${mediaQuery.lessThan('tablet')`${SubTitleMobileCss}`}
`;

export const RowStyledSocialAndMenu = styled(Row)`
    margin-top: 70px;
`;
export const Paragraph = styled.p`
    font-size: 13px;
    margin: 5px 0px;
    color: ${color('base.white')};
`;

const SocialMediaMobileCss = css`
    ul {
        justify-content: center;
    }
`;

export const SocialMedia = styled.div`
    margin-top: 20px;
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
            margin-right: 7px;
            a {
                width: 35px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${color('base.white')};
                img {
                    width: 25px;
                }
                .mispay-icon {
                    display: flex;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${SocialMediaMobileCss}`}
`;

const ItemContainerMobileCss = css`
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .item {
        margin-right: 0;
        margin-bottom: 25px;
        .menu-title {
            font-size: 18px;
            font-weight: 700;
        }
        .menu {
            li {
                a {
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    .item {
        margin-right: 10%;
        &:last-child {
            margin-right: 0;
        }
        .menu-title {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: ${fonts.size.medium};
            color: ${color('base.white')};
        }
        .menu {
            li {
                a {
                    margin-bottom: 5px;
                    font-size: 13px;
                    font-weight: normal;
                    color: ${color('base.white')};
                    &:hover {
                        color: ${color('primary.500')};
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${ItemContainerMobileCss}`}
`;

const CopyrightMobileCss = css`
    .ant-col {
        text-align: center;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        ul {
            justify-content: center;
        }
    }
`;

export const Copyright = styled(Row)`
    margin-top: 30px;
    padding: 30px 0px;
    span {
        font-size: 13px;
        color: ${color('base.white')};
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
            position: relative;
            &:first-child {
                padding-right: 10px;
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    right: 4px;
                    top: 4px;
                    width: 1px;
                    height: 70%;
                    background-color: ${color('base.white')};
                }
            }
            a {
                font-size: 13px;
                color: ${color('base.white')};
            }
        }
    }
    ${mediaQuery.lessThan('tablet')`${CopyrightMobileCss}`}
`;
