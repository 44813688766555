import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const MobileTitleCss = css`
    font-size: ${fonts.size.xxxlarge};
`;

export const Title = styled.h1`
    font-size: ${fonts.size.xxxxlarge};
    margin: 0;
    color: ${color('base.black')};
    ${mediaQuery.lessThan('tablet')`${MobileTitleCss}`}
`;

const MobileSubTitleCss = css`
    font-size: 18px;
`;

export const SubTitle = styled.h2`
    font-size: ${fonts.size.xxxlarge};
    font-weight: 500;
    margin: 0;
    color: ${color('base.black')};
    ${mediaQuery.lessThan('tablet')`${MobileSubTitleCss}`}
`;

const ContainerMobileCss = css`
    &.size-large {
        h1 {
            font-size: 42px;
        }
    }
`;

export const Container = styled.div`
    &.center {
        text-align: center;
    }
    &.size-large {
        h1 {
            font-size: 60px;
        }
    }
    &.font-sharp {
        h1,
        h2 {
            font-family: 'Sharp Grotesk';
        }
    }
    ${mediaQuery.lessThan('tablet')`${ContainerMobileCss}`}
`;
