export const routerPaths = {
    home: '/',
    shop: '/shop',
    faqs: '/faqs',
    business: '/business',
    howItWorks: '/how-it-works',
    aboutUs: '/about',
    shariyaCompliance: '/shariya-compliance',
    contactUs: '/contact-us',
    inStore: '/in-store',
    online: '/online',
    integration: '/integration',
    privacy: '/privacy-policy',
    termsConditions: '/terms-and-conditions',
    policy: '/policy',
    beAPart: '/be-a-part'
};
