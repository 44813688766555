import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import SpriteSVG from 'components/svg-icon/SpriteSVG';
import { theme } from 'common/theme/color';
import i18n from 'assets/i18n';
import App from './App';
import { initializeGTM } from './gtm';
import 'assets/styles/_sharp-grotesk.css';

void initializeGTM();
void i18n.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <App />
                <SpriteSVG />
            </ThemeProvider>
        </Router>
    </React.StrictMode>
);
