import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const AboutMobileCss = css`
    .let-yourself-be-inspired {
        .banner {
            display: none;
        }
    }
    .our-operating {
        h1,
        p {
            text-align: center;
        }
    }
`;

export const About = styled.div`
    .let-yourself-be-inspired {
        margin-top: 40px;
        .ant-row {
            margin-top: 40px;
        }
    }
    .our-operating {
        margin-top: 80px;
        .info {
            h1 {
            }
            p {
                font-size: 20px;
                font-weight: 400;
                color: ${color('base.black')};
            }
        }
        .slider {
            .swiper-pagination {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                .swiper-pagination-bullet {
                    display: block;
                    transition: all 0.5s ease;
                    margin: 0px 3px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: ${color('neutral.200')};
                    &-active {
                        transition: all 0.5s ease;
                        width: 40px;
                        border-radius: 40px;
                        background-color: ${color('primary.500')};
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${AboutMobileCss}`}
`;
