import * as S from "./index.styles";
import nl2br from "react-nl2br";
import {useTranslation} from "react-i18next";
//ui
import {Col, Row} from "antd";

const ShoppingGameOn = ({downloadAppCard, qr}: any) => {
    const {t} = useTranslation();
    return (
        <S.ShoppingGameOnStyled>
            <Row>
                <Col xs={24} lg={13} className="information">
                    <h1 className="title">{nl2br(downloadAppCard?.title)}</h1>
                    <p className="paragraph" dangerouslySetInnerHTML={{__html: downloadAppCard?.description}}/>
                    <div className="get-the-mis-pay-app">
                        <img src={qr} alt="qr"/>
                        <span>{t("widget.getTheMisPayApp")}</span>
                    </div>
                </Col>
                <Col xs={24} lg={11} className="banner">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${downloadAppCard?.image?.url})`,
                        }}
                    ></div>
                    <div
                        className="phone"
                    ></div>
                </Col>
            </Row>
        </S.ShoppingGameOnStyled>
    );
};

export default ShoppingGameOn;