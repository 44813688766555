import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

const baseButtonStyles = `
    height: 40px;
    padding: 0 20px;
    border-radius: 25px;
    font-size: ${fonts.size.large};
    font-weight: ${fonts.weight.medium};
    span {
        font-size: 14px;
    }
`;

export const ButtonStyled = styled(AntButton)`
    ${baseButtonStyles}

    &.ant-btn-default {
        background-color: ${color('base.white')};
        border: 1px solid ${color('base.white')};
        color: ${color('primary.500')};
        &:hover {
            background-color: ${color('base.white')} !important;
            border-color: ${color('primary.600')} !important;
            color: ${color('primary.600')} !important;
        }
    }
    &.ant-btn-primary {
        box-shadow: none !important;
        color: ${color('base.white')};
        background-color: ${color('primary.500')};
        &:hover {
            background-color: ${color('primary.600')} !important;
        }
        &:active {
            background-color: ${color('primary.400')} !important;
        }
    }
    &.ant-btn-link {
        border: none;
        color: ${color('base.white')} !important;
        &:hover {
            color: ${color('base.black')} !important;
        }
    }
    &.ant-btn-text {
        background-color: ${color('base.white')};
        color: ${color('primary.500')};
        &:hover {
            color: ${color('base.white')} !important;
            background-color: ${color('primary.500')} !important;
        }
    }
    &.ant-btn-lg {
        height: 45px;
        padding: 0 25px;
    }
    &.ant-btn-sm {
        height: 35px;
        padding: 0 15px;
    }
`;
