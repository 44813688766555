import * as S from './index.styles';

// widgets
import Main from 'widgets/main';

// comp

import {BeApartBanner} from './index.styles';

const BeAPart = () => {
    return (
        <Main>
            <S.Privacy>
                <BeApartBanner />
                <section className="children">
                    <div className="container">
                        {/* eslint-disable-next-line */}
                        <iframe
                            aria-label="MISPay"
                            allow="camera;"
                            style={{height: '1400px', width: '99%', border: 'none'}}
                            src="https://forms.zohopublic.sa/zohoadmin/form/MisPay1/formperma/65Q2Ym5rAv2hvJXQbfnF1mXJZ-hq4YzvLdDKcDuKhS4?zf_enablecamera=true&zf_lang=ar"
                        ></iframe>
                    </div>
                </section>
            </S.Privacy>
        </Main>
    );
};

export default BeAPart;
