import * as S from "./index.styles";
import { PartnerInterface } from "common/interface/general";

const Partner = ({ percentage, info }: PartnerInterface) => {
  return (
    <S.Partner>
      <div className="percentage-and-info">
        <div className="percentage">{percentage}</div>
        <div className="info" dangerouslySetInnerHTML={{__html: info}} />
      </div>
    </S.Partner>
  );
};

export default Partner;
