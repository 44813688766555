import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const ShoppingGameOnMobileCss = css`
    .banner {
        display: none !important;
    }
    .information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px 30px 20px;
        .title {
            font-size: 52px;
            line-height: 52px;
            text-align: center;
            max-width: 100%;
        }
        .paragraph {
            font-size: ${fonts.size.xxlarge};
            text-align: center;
        }
        .get-the-mis-pay-app {
            width: 90%;
            span {
                font-size: ${fonts.size.xxlarge};
            }
        }
    }
`;

export const ShoppingGameOnStyled = styled.div`
    max-width: 1060px;
    margin: 0 auto;
    border-radius: 40px;
    background-color: ${color('neutral.100')};
    margin-bottom: 40px;

    .information {
        padding: 40px 0px 40px 40px;

        .title {
            font-size: ${fonts.size.xxxxxxlarge};
            font-weight: 600;
            line-height: 80px;
            max-width: 350px;
            margin: 0 0;
            color: ${color('base.black')};
        }

        .paragraph {
            font-size: ${fonts.size.xxlarge};
            font-weight: 400;
            color: ${color('base.black')};
            max-width: 400px;
        }

        .get-the-mis-pay-app {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 340px;
            border-radius: 20px;
            padding: 15px;
            border: 1px solid ${color('neutral.200')};

            img {
                width: 95px;
                height: 95px;
                border-radius: 10px;
                margin-right: 15px;
                border: 1px solid ${color('neutral.200')};
                background-color: ${color('base.white')};
            }

            span {
                font-size: ${fonts.size.xxxlarge};
                font-weight: 500;
                line-height: 37px;
                color: ${color('base.black')};
            }
        }
    }

    .banner {
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            display: block;
            background-position: center;
            background-size: cover;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
        }

        .phone {
            width: 76%;
            height: 76%;
            top: 82px;
            left: -85px;
            display: block;
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    ${mediaQuery.lessThan('xltablet')`${ShoppingGameOnMobileCss}`}
`;
