import styled, { css } from 'styled-components';
import { mediaQuery } from 'common/theme/media';
import { fonts } from 'common/theme/font';
import { color } from 'common/utils/pickColor';

const mobileCss = css`
    padding: 10px 15px;
    .left-side {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .menu-bar {
            .mispay-icon {
                font-size: 22px;
                height: 22px;
            }
        }
        .logo {
            margin: 0px 6px;
            a {
                display: flex;
                align-items: center;

                img {
                    max-width: 66px;
                }
            }
        }
    }
    .right-side {
        .ant-btn {
            padding: 0 12px;
            height: 35px;
            span {
                font-size: 12px;
                line-height: 25px;
            }
        }
        .ant-btn-default {
            margin-right: 7px;
        }
        .ant-btn-link {
            padding-left: 8px;
            padding-right: 0 !important;
        }
    }
`;
const tabletCss = css`
    display: flex !important;
`;

export const HeaderMobile = styled.div`
    display: none !important;
    position: absolute;
    width: 100%;
    top: 0px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 9;
    .left-side {
        display: flex;
        align-items: center;
        .menu-bar {
            .mispay-icon {
                color: white;
                display: block;
                font-size: 25px;
                max-height: 20px;
            }
        }
        .logo {
            margin-left: 20px;
            a {
                img {
                    max-width: 120px;
                }
            }
        }
    }
    .right-side {
        .ant-btn-default {
            margin-right: 15px;
        }
    }
    .ant-drawer {
        .ant-drawer-content-wrapper {
            width: 100% !important;
            max-width: 100%;
            .ant-drawer-content {
                .ant-drawer-body {
                    .logo-and-close {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        > a {
                            img {
                                max-width: 66px;
                            }
                        }
                        .close {
                            .mispay-icon {
                            }
                        }
                    }
                    .menu {
                        margin-top: 20px;
                        .ant-menu {
                            border-inline-end: none;
                            .ant-menu-item {
                                padding-left: 0 !important;
                                margin-bottom: 20px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                .ant-menu-title-content {
                                    font-size: ${fonts.size.xxlarge};
                                    font-weight: 500;
                                    color: ${color('base.black')};
                                    background-color: inherit !important;
                                }
                                &.ant-menu-item-selected {
                                    background-color: inherit !important;
                                }
                            }
                            .ant-menu-submenu {
                                margin-bottom: 20px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                .ant-menu-submenu-title {
                                    font-size: ${fonts.size.xxlarge};
                                    font-weight: 500;
                                    padding-left: 0 !important;
                                    color: #344054;
                                    .ant-menu-title-content {
                                    }
                                    .ant-menu-submenu-arrow {
                                        display: none !important;
                                    }
                                }
                                .ant-menu-sub {
                                    background: none !important;
                                    margin-left: 15px;
                                    .ant-menu-item {
                                        margin-bottom: 0 !important;
                                        .ant-menu-title-content {
                                            font-size: 18px;
                                            font-weight: 600;
                                            color: #14151e;
                                        }
                                    }
                                }
                                &.ant-menu-submenu-active {
                                    .ant-menu-submenu-title {
                                        background-color: inherit !important;
                                    }
                                }
                                &.ant-menu-submenu-open {
                                    .ant-menu-submenu-title {
                                        background-color: inherit !important;
                                    }
                                }
                            }
                        }
                    }
                    .login-and-get-the-app {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        column-gap: 16px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        padding: 20px;
                        max-height: 75px;
                        background-color: #f7f8fb;
                    }
                }
            }
        }
    }
    &.sticky {
        position: fixed;
        z-index: 9999;
        box-shadow: 0px -3px 15px #d5d5d5;
        background-color: white;

        .ant-btn-link {
            span {
                color: ${color('base.black')};
            }
        }

        .left-side {
            .menu-bar {
                .mispay-icon {
                    color: black;
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${tabletCss}`}
    ${mediaQuery.lessThan('tablet')`${mobileCss}`}
`;
