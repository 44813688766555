import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheetManager, StylisPlugin } from 'styled-components';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { RTLSupportContextType, RTLSupportProviderProps } from './types';

export const RTLSupportContext = createContext<RTLSupportContextType>({
    isRTL: false,
});

export function RTLSupportProvider({
    children,
    root,
}: RTLSupportProviderProps) {
    const { i18n } = useTranslation();

    const dir = i18n.dir();
    const rootEl = root || document.body;
    const isRTL = dir === 'rtl';
    const stylisPlugins = isRTL
        ? [stylisRTLPlugin as unknown as StylisPlugin]
        : undefined;

    rootEl.dir = dir;

    return (
        <RTLSupportContext.Provider value={{ isRTL }}>
            <StyleSheetManager stylisPlugins={stylisPlugins}>
                {children}
            </StyleSheetManager>
        </RTLSupportContext.Provider>
    );
}

export function withRTLSupport<T extends JSX.IntrinsicAttributes>(
    Component: React.ComponentType<T>,
    root?: HTMLElement
) {
    return (props: T) => (
        <RTLSupportProvider root={root}>
            <Component {...props} />
        </RTLSupportProvider>
    );
}
