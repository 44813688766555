import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const MobileBoxCss = css`
    border-radius: 8px;

    .title-and-subImg {
        img {
            max-height: 35px;
            max-width: 35px;
        }
        h1 {
            font-size: ${fonts.size.large};
            margin-left: 10px;
        }
    }

    .banner {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`;

export const Box = styled.div`
    border-radius: 30px;
    background-color: ${color('neutral.100')};

    height: 230px;

    .banner {
        background-size: cover;
        background-position: center;
        height: 178px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    img {
        max-width: 100%;
        display: block;
    }

    .title-and-subImg {
        display: flex;
        align-items: center;
        padding: 10px 12px;

        .merchant-logo {
            max-width: 32px;
            max-height: 32px;
            display: block;
        }

        h1 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            margin-left: 15px;
            color: ${color('base.black')};
        }
    }

    ${mediaQuery.lessThan('tablet')`${MobileBoxCss}`}
`;
