import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const DynamicCardMobileCss = css`
    .image {
        img {
            max-width: 100%;
        }
    }
    .card-info {
        .title {
            text-align: center;
        }
        .subTitle {
            text-align: center;
        }
    }
    .ant-row {
        &.ltr {
            flex-direction: column-reverse;
        }
        &.rtl {
            flex-direction: column-reverse;
        }
    }
    .ant-btn {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
    }
`;

const DynamicCardTabletCss = css`
    .card-info {
        .title {
            font-size: ${fonts.size.xxlarge};
            line-height: 35px;
        }
        .subTitle {
            font-size: 18px;
        }
        .ant-btn {
            span {
            }
        }
    }
    .extra {
        .item-list {
            .item {
                .text {
                    .extra-tit {
                        font-size: 18px;
                    }
                    .extra-sub {
                        font-size: ${fonts.size.medium};
                    }
                }
            }
        }
    }
`;

export const DynamicCardStyled = styled.div`
    .ant-row {
        margin: 40px 0px;
        align-items: center;
        &.rtl {
            flex-direction: row-reverse;
        }
    }
    .image {
        img {
            max-width: 100%;
        }
    }
    .card-info {
        .title {
            margin-top: 0;
            font-size: ${fonts.size.xxxxxxlarge};
            font-weight: 600;
            line-height: 75px;
            margin-bottom: 0;
            color: ${color('base.black')};
        }
        .subTitle {
            font-size: ${fonts.size.xxlarge};
            font-weight: 400;
            color: ${color('base.black')};
        }
    }
    .extra {
        .item-list {
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .mispay-icon {
                    margin-right: 15px;
                    svg {
                        font-size: 40px;
                    }
                }
                .text {
                    .extra-tit {
                        font-size: ${fonts.size.xxxlarge};
                        font-weight: 500;
                        color: ${color('base.black')};
                        margin: 0;
                    }
                    .extra-sub {
                        font-size: 18px;
                        font-weight: 400;
                        color: ${color('base.black')};
                        margin: 0;
                    }
                }
            }
        }
    }
    ${mediaQuery.lessThan('xltablet')`${DynamicCardTabletCss}`}
    ${mediaQuery.lessThan('tablet')`${DynamicCardMobileCss}`}
`;
