import { DefaultTheme } from 'styled-components';
import { Palette } from 'types/theme';

const colors: Palette = {
    primary: {
        700: '#360785',
        600: '#490CB1',
        500: '#6322D0',
        400: '#824ED9',
        300: '#A17AE3',
        250: '#b5affb',
        200: '#E0D3F6',
        100: '#F5EEFE',
        50: '#FBF8FF'
    },
    secondary: {
        700: '#8BA806',
        600: '#9CBC11',
        500: '#BEDD34',
        400: '#E2FF3C',
        300: '#EDFF83',
        200: '#F2FFA8',
        100: '#F8FFD2',
        50: '#F8FFD2'
    },
    highlight: {
        500: '#DF4D4E',
        400: '#EC5A5B',
        300: '#FF8585',
        200: '#FFB0B0',
        100: '#FFD1D1',
        50: '#FFECEC'
    },
    neutral: {
        600: '#1A1D1E',
        500: '#3C4345',
        400: '#586265',
        300: '#737B7D',
        200: '#CDCECF',
        100: '#F1F3F7',
        50: '#FAF9F9'
    },
    error: {
        500: '#F00A0A',
        400: '#FF5757',
        300: '#FD8181',
        200: '#FEAFAF',
        100: '#FFEBEB'
    },
    warning: {
        500: '#FF9211',
        400: '#FF9E2C',
        300: '#FFB45B',
        200: '#FFCB8D',
        100: '#FDE8CE'
    },
    success: {
        500: '#00D669',
        400: '#33E58B',
        300: '#7AEDB3',
        200: '#A9F7CF',
        100: '#E1FEEF'
    },
    information: {
        500: '#45AAF2',
        400: '#6CBCF5',
        300: '#9CD2F9',
        200: '#C6E7FF',
        100: '#E9F6FF'
    },
    base: {
        black: '#121414',
        white: '#FFFFFF'
    },
    general: {
        hippieBlue: '#4D9FA5',
        ziggurat: '#B0D8DB',
        cinder: '#14151D',
        whisper: '#f7f8fb',
        athensGray: '#F1F3F7',
        tuna: '#35373F',
        tuna2: '#393b43',
        persianGreen: '#03929A',
        osloGray: '#898A8E',
        catskillWhite: '#F2F4F8',
        mischka: '#D2D6DD',
        mischka2: '#D0D5DD',
        santasGray: '#A1A1A5',
        ghost: '#C6CBD6',
        cornflowerBlue: '#a5d9dd',
        easternBlue: '#1ea1a7',
        cornflowerBlue2: '#6195ED',
        cinder2: '#14151E',
        mystic: '#dfe5ee',
        ebony: '#101828',
        athensGray2: '#e9ebee',
        catskillWhite2: '#E3E9F2',
        swansDown: '#d7ebef',
        aquaIsland: '#a5d9dc',
        catskillWhite3: '#f1f4f8',
        black: '#000000',
        white: '#FFFFFF'
    },
    status: {
        error: '#E6385A',
        lightError: '#FCEBEF',
        success: '#60DCA1',
        lightSuccess: '#EFFBF6',
        warning: '#F5BD83',
        info: '#27AAE1',
        mediumRed: '#F26F89',
        darkRed: '#D1183D'
    }
};

export const theme: DefaultTheme = { colors };
