import { fonts } from 'common/theme/font';
import { mediaQuery } from 'common/theme/media';
import { color } from 'common/utils/pickColor';
import styled, { css } from 'styled-components';

const AccordionMenuMobileCss = css`
    .ant-collapse {
        .subTitle {
            font-size: 25px;
        }
        .ant-collapse-header {
            margin: 12px 0px;
            .ant-collapse-header-text {
                font-size: 18px !important;
                line-height: 20px;
            }
            .ant-collapse-expand-icon {
                top: 12px;
            }
        }
    }
`;

export const AccordionMenuStyled = styled.div`
    .ant-collapse {
        border: 0;
        background: transparent;
        border-radius: 0;

        &-expand-icon {
            width: 20px !important;
            height: 20px !important;
            justify-content: center;
            position: absolute;
            right: 0;
            border-radius: 15px;
            padding-inline-end: 0 !important;
            transform: rotate(90deg);
            background-color: ${color('neutral.100')};

            svg {
                color: ${color('neutral.500')};
            }
        }

        &-header {
            margin: 20px 0;
        }

        &-content {
            border-color: ${color('general.mystic')};
            background: transparent;

            &-active {
                border: 0;
                padding-top: 0;

                .ant-collapse-content-box {
                    padding-top: 0;
                }
            }

            &-box {
                padding-left: 0 !important;
                padding-right: 0 !important;

                ul {
                    li {
                        list-style: inside;
                    }
                }
            }
        }

        &-item {
            margin: 0px;
            border-top: 1px solid #dfe5ee !important;
            border-bottom: 0;
            border-radius: 0;

            &:last-child {
                border-bottom: 1px solid #dfe5ee !important;
                border-radius: 0px 0px 0px 0px !important;
            }

            .ant-collapse-header {
                align-items: center;
                padding-left: 0;
                padding-right: 0;

                &-text {
                    font-size: ${fonts.size.xxlarge};
                    font-weight: 500;
                }
            }

            &-active {
                .ant-collapse-header-text {
                    color: ${color('primary.500')};
                }

                .ant-collapse-expand-icon {
                    transform: rotate(180deg);
                    background-color: ${color('primary.500')};

                    svg {
                        color: ${color('base.white')};
                    }
                }
            }

            &:last-child {
                .ant-collapse-header {
                    border-radius: 0px 0px 0px 0px !important;
                }
            }
        }
    }

    ${mediaQuery.lessThan('tablet')`${AccordionMenuMobileCss}`}
`;
