import * as S from './index.styles';
import {BasicCardInterface} from 'common/interface/general';

//comp
import {Button} from 'components/button';
import {SvgIcon} from 'components/svg-icon';

const BasicCard = ({icon, title, subTitle, btnText, link}: BasicCardInterface) => {
    return (
        <S.BasicCardStyled>
            <div className='icon'>
                <SvgIcon name={icon}/>
            </div>
            <div className='info'>
                <h1 className='title'>{title}</h1>
                <h2 className='subTitle' dangerouslySetInnerHTML={{__html: subTitle}}/>
                <a href={link}><Button type='link'>{btnText}</Button></a>
            </div>
        </S.BasicCardStyled>
    )
}

export default BasicCard;