import { useState } from 'react';
import { routerPaths } from 'config/routerPaths';
import * as S from './index.styles';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

//comp
import { SvgIcon } from 'components/svg-icon';
import { Button } from 'components/button';
import Logo from 'components/logo';
import HeaderMenuMobile from 'components/header-menu-mobile';
import ChangeLanguage from 'components/change-language';

const HeaderMobile = ({ isSticky }: any) => {
    const { home, shop, business, howItWorks, integration } = routerPaths;
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const menuProps = {
        mode: 'inline',
        defaultOpenKeys: ['sub1', 'sub2']
    };

    const menuItems = [
        { key: '1', link: home, title: t('header.menu.home') },
        { key: '2', link: shop, title: t('header.menu.shop') },
        { key: '3', link: business, title: t('header.menu.business') },
        { key: '4', link: howItWorks, title: t('header.menu.how-it-works') },
        { key: '5', link: integration, title: t('header.menu.integration') }
    ];

    /*
  const subMenuItems = [
    {
      key: "sub1",
      title: "Solutions",
      items: [
        { key: "4", title: "Online" },
        { key: "5", title: "In-Store" },
      ],
    },
    {
      key: "sub2",
      title: "Industries",
      items: [
        { key: "6", title: "Fashion" },
        { key: "7", title: "Beauty" },
        { key: "8", title: "Home" },
      ],
    },
  ];
  */
    return (
        <>
            <S.HeaderMobile className={isSticky ? 'sticky header-mobile' : 'header-mobile'}>
                <div className="left-side">
                    <div className="menu-bar" onClick={showDrawer}>
                        <SvgIcon name="menu-bar" />
                    </div>
                    <div className="logo">
                        <Logo type={isSticky ? 'dark' : 'light'} />
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        type="default"
                        onClick={() => {
                            window.open(`https://console.mispay.co/`, '_blank');
                        }}
                    >
                        {t('header.menu.login')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            window.open(
                                `https://www.mispay.co/onboarding?lang=${i18n.language}`,
                                '_blank'
                            );
                        }}
                    >
                        {t('header.menu.signup')}
                    </Button>
                    <ChangeLanguage />
                </div>
            </S.HeaderMobile>
            <Drawer
                closable={false}
                onClose={onClose}
                open={open}
                getContainer={'.header-mobile'}
                placement="left"
            >
                <div className="logo-and-close">
                    <Logo type="dark" />
                    <div className="close" onClick={onClose}>
                        <SvgIcon name="close-icon" />
                    </div>
                </div>
                <div className="menu">
                    <HeaderMenuMobile
                        menu={menuProps}
                        menuItems={menuItems}
                        //subMenuItems={subMenuItems}
                    />
                </div>
                <div className="login-and-get-the-app">
                    <Button
                        type="default"
                        onClick={() => {
                            window.open(`https://console.mispay.co/`, '_blank');
                        }}
                    >
                        {t('header.menu.login')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            window.open(
                                `https://www.mispay.co/onboarding?lang=${i18n.language}`,
                                '_blank'
                            );
                        }}
                    >
                        {t('header.menu.signup')}
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

export default HeaderMobile;
