import { useCallback, useEffect, useState } from "react";
import * as S from "./index.styles";
import { useTranslation } from "react-i18next";

//swiper
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// widgets
import Main from "widgets/main";
import LetYourselfBeInspired from "widgets/let-yourself-be-inspired";

//ui
import { Col, Row } from "antd";

// comp
import Banner from "components/banner";
import Title from "components/title";
import Operating from "components/operating";
import usePageApi from "../../api/page/page.api";
import { AboutPageResponse } from "../../api/page/page.model";

const About = () => {
  const { t } = useTranslation();
  const [pagePayload, setPagePayload] = useState<AboutPageResponse>();
  const pageApi = usePageApi();

  const fetchHomeData = useCallback(async () => {
    try {
      const response = await pageApi.getAbout();

      setPagePayload(response.result);
    } catch (error) {
      console.log("error", error);
    }
  }, [pageApi]);

  useEffect(() => {
    fetchHomeData();
  }, [fetchHomeData]);
  const heroImage = pagePayload?.widgets.heroImage;
  const greenCard = pagePayload?.widgets?.greenCard;
  const blackCard = pagePayload?.widgets?.blackCard;
  const textCard = pagePayload?.widgets?.textCard;
  const carasuelMap = pagePayload?.widgets?.carasuel;
  return (
    <Main>
      <S.About>
        <Banner
          title={heroImage?.title}
          subTitle={heroImage?.desc}
          image={heroImage?.image.url}
          btnText={heroImage?.buttonLabel}
          link={heroImage?.link}
          mobileImage={heroImage?.mobileImage?.url}
        />
        <section className="children">
          <div className="container">
            <div className="let-yourself-be-inspired">
              <Title
                type="center"
                title={t("about.letYourselfTitle")}
                subTitle={t("about.letYourselfSubTitle")}
              />
              <LetYourselfBeInspired
                greenCard={greenCard}
                blackCard={blackCard}
              />
            </div>
            <div className="our-operating">
              <Row gutter={[20, 20]}>
                <Col xs={24} md={8} className="info">
                  <Title title={textCard?.title} />
                  <p dangerouslySetInnerHTML={{ __html: textCard?.content }} />
                </Col>
                <Col xs={24} md={16} className="slider">
                  <Swiper
                    modules={[Pagination]}
                    spaceBetween={12}
                    slidesPerView={1}
                    pagination
                  >
                    {carasuelMap?.map((item: any) => (
                      <SwiperSlide key={item.id}>
                        <Operating
                          icon={item.icon}
                          title={item.title}
                          subTitle={item.content}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </S.About>
    </Main>
  );
};

export default About;
